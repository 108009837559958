import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import Button from '../../button';
import useModal from '../../../hooks/use-modal.hook';
import Modal from '..';

export interface ConfirmModalProps {
  onClose: (confirm?: boolean) => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ onClose }) => {
  const { closeModal } = useModal();

  const handleClose = (confirm?: boolean) => {
    onClose(confirm);
    closeModal();
  };

  return (
    <Modal>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>Are you sure you want to do the thing?</DialogContent>
      <DialogActions>
        <Button label="Cancel" type="cancel" onClick={() => handleClose(false)} />
        <Button onClick={() => handleClose(true)} label="Yes" />
      </DialogActions>
    </Modal>
  );
};

export default ConfirmModal;
