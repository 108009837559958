import { Stack, Theme } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import Icon, { IconType } from '../icon';
import Text from '../text';
import { createUseStyles } from 'react-jss';

interface Props {
  onClick: () => void;
  label?: string;
  icon: IconType;
  active?: boolean;
  border?: boolean;
}

const useStyles = createUseStyles<string, { width: number }>((theme: Theme) => ({
  linkBarItem: {
    cursor: 'pointer',
    width: '100%',
    height: 70,
    boxSizing: 'border-box',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover,&.active': {
      backgroundColor: '#f8f8f814',
    },
    '&.border': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));

const LinkBarItem: FC<Props> = ({ onClick, label = '', icon, active = false, border = false }) => {
  const styles = useStyles();
  const compClass = classNames(styles.linkBarItem, { active, border });
  const hasLabel = !!label;
  return (
    <Stack gap={0.5} role="button" onClick={onClick} className={compClass}>
      <Icon name={icon} />
      {hasLabel && (
        <Text size="small" dotdot>
          {label}
        </Text>
      )}
    </Stack>
  );
};

export default LinkBarItem;
