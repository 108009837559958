import { LoadState } from '@/lib/helpers';
import { OrgBrandingModel } from '@/lib/models/org-branding.model';
import { AuthUser } from '@/lib/services/auth.service';
import { getCachedOrgBranding } from '@/lib/services/org.service';
import { createContext } from 'react';
import SurepathLogoCarbon from '@/assets/logo-carbon-0924.svg';
import SurepathLogomarkCarbon from '@/assets/logomark-carbon-0924.svg';

export type BreadCrumb = 'group' | 'context' | 'policy';

export interface AppSettings {
  loadState: LoadState;
  signedIn: boolean;
  signIn: (authUser: AuthUser) => boolean;
  signOut: () => Promise<boolean>;
  user: AuthUser | null;
  pageTitle: { plain: string; breadcrumb?: BreadCrumb | BreadCrumb[] };
  setPageTitle: (title: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => void;
  branding: OrgBrandingModel;
  isDemo: boolean;
}

const CachedOrgBranding = getCachedOrgBranding();

export const SpOrgBranding = new OrgBrandingModel({
  orgName: 'SurePath AI',
  lightThemeLogo: SurepathLogoCarbon,
  portalBadge: {
    image: SurepathLogomarkCarbon,
    title: 'SurePath AI Assistant',
  },
  portalWelcome: {
    title: '',
    message: '',
    learnMoreLink: '',
  },
  supportEmail: '',
  favicon: '/favicon-cobalt-0924.png',
});

export const DefaultOrgBranding = CachedOrgBranding?.enabled ? CachedOrgBranding : SpOrgBranding;

export const AppContext = createContext<AppSettings>({
  loadState: 'unloaded',
  signedIn: false,
  signIn: () => false,
  signOut: async () => Promise.resolve(false),
  user: null,
  pageTitle: { plain: '' },
  setPageTitle: () => null,
  branding: DefaultOrgBranding,
  isDemo: false,
});
