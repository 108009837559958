import { AxiosResponse } from 'axios';
import { JsonObject } from '../helpers';

export type ApiResponse = JsonObject | JsonObject[] | null;

export type ApiFileResponse = {
  name: string;
  summary: string;
  purpose: string;
};

export type ApiUploadResponse = {
  file?: ApiFileResponse;
  threadId: string;
  error?: string;
};

export type ServiceResponse = {
  error?: string;
};

export const DEFAULT_SERVICE_ERROR = 'There was a service error';

export const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};
