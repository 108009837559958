import { JsonObject } from '../helpers';
import { BaseView } from './base.view';

export class UserPolicyView extends BaseView {
  portalEnabled: boolean;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof UserPolicyView, unknown>;

    this.portalEnabled = !!data.portalEnabled;
  }
}
