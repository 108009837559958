import { v4 as uuidv4 } from 'uuid';

// https://stackoverflow.com/questions/55550096/ts2322-type-timeout-is-not-assignable-to-type-number-when-running-unit-te
export type JsTimeout = undefined | ReturnType<typeof setTimeout>;

export type JsonObject = Record<string, unknown>;

export type FlatObject = Record<string, string>;

export type LoadState = 'unloaded' | 'loading' | 'loaded';

export type DateRangeValue = [Date | null, Date | null];

export type DtSort = {
  columnName: string;
  direction: 'asc' | 'desc';
};

export type DtFilter = {
  keyword: string;
};

export type DtPage = {
  page: number;
  pageSize: number;
  sort?: DtSort;
};

export type DtColumn = {
  name: string;
  label: string;
  format?: string;
  decimals?: number;
  flex?: number;
  sortable?: boolean;
};

export type Dataset<T> = DtPage & {
  rows: T[];
  total: number;
};

export type DtParams = { page: number; pageSize: number; sort?: string; sortDirection?: string };

export type UiOption = {
  label: string;
  value: string;
  match?: boolean;
  disabled?: boolean;
  meta?: Record<string, unknown>;
};

export const getDatasetParams = (page: number, pageSize: number, sort?: DtSort): DtParams => {
  const { columnName, direction } = sort || {};
  const params: DtParams = {
    page,
    pageSize,
  };

  if (columnName) {
    params.sort = columnName;
    params.sortDirection = direction || 'asc';
  }

  return params;
};

export const pathIsActive = (path: string): boolean => {
  const currentPath = window.location.pathname;
  return currentPath === path;
};

export const pathIsExternal = (path: string): boolean =>
  Boolean(String(path).match(/^http(s?):\/\//));

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getRandomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getIdFromLabel = (label: string): string =>
  String(label)
    .replace(/ +/g, '-')
    .replace(/[^A-Za-z0-9-]+/, '')
    .toLowerCase();

export const getFirstLastName = (name: string): { first: string; last: string } => {
  const nameParts = name.split(' ');
  const first = nameParts.shift() || '';
  return { first, last: nameParts.join(' ') };
};

export const getUUID = (): string => uuidv4();

// https://stackoverflow.com/questions/136505/searching-for-uuids-in-text-with-regex
export const isUUID = (val: string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(val);

export const niceFilesize = (bytes: number): string => {
  if (bytes < 1024) {
    return bytes + ' b';
  }
  if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' kb';
  }

  return (bytes / (1024 * 1024)).toFixed(2) + ' mb';
};

// https://stackoverflow.com/questions/29899364/how-do-you-scroll-to-the-position-of-the-cursor-in-a-textarea#:~:text=Click%20somewhere%20in%20the%20textarea,%22Scroll%20to%20Cursor%22%20button
export const scrollToCursor = (inputEl: HTMLInputElement): void => {
  inputEl.blur();
  inputEl.focus();
};
