import { Box, Theme } from '@mui/material';
import classNames from 'classnames';
import { DragEvent, FC, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  onDrop: (fileList: FileList) => void;
  children: ReactNode;
  className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  fileDropZone: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    opacity: 0.5,
  },
}));

const FileDropzone: FC<Props> = ({ className, onDrop, children }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const styles = useStyles();

  // fml https://stackoverflow.com/questions/11573710/event-datatransfer-files-is-empty-when-ondrop-is-fired
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    onDrop(event.dataTransfer.files);
    setShowOverlay(false);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!event.dataTransfer.types.includes('Files')) {
      return;
    }
    !showOverlay && setShowOverlay(true);
  };

  const handleDragExit = () => {
    setShowOverlay(false);
  };

  const compClass = classNames(styles.fileDropZone, className);

  return (
    <Box onDragEnter={handleDragEnter} onDragOver={handleDragEnter} className={compClass}>
      {children}
      {showOverlay && (
        <Box onDragLeave={handleDragExit} onDrop={handleDrop} className={styles.overlay}></Box>
      )}
    </Box>
  );
};

export default FileDropzone;
