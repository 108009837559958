import { FC, ReactNode, useEffect } from 'react';
import useApp from '../../hooks/use-app.hook';
import { Box } from '@mui/material';
import { UseQueryResult } from 'react-query';
import useQueryHelper from '../../hooks/use-query-helper';
import Loader from '../loader';
import { createUseStyles } from 'react-jss';

export interface PageProps {
  children?: ReactNode;
  title?: string;
  query?: UseQueryResult;
}

const useStyles = createUseStyles({
  page: {
    height: 'calc(100% - 70px)', // the 70px is for the header in @src/components/layouts/app
    width: '100%',
  },
});

const Page: FC<PageProps> = ({ children, title = '', query }) => {
  const { setPageTitle } = useApp();
  const styles = useStyles();
  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);

  const { showLoader } = useQueryHelper(query);

  return (
    <Box className={styles.page}>
      {showLoader && (
        <Box height="100%" width="100%" display="flex" alignItems="flex-end">
          <Loader />
        </Box>
      )}
      {!showLoader && (
        <Box height="100%" width="100%">
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Page;
