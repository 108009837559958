import { FC, ReactNode, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import AppSidebar from '../app-sidebar';
import OrgLogo from '../org-logo';

interface Props {
  children: ReactNode;
}

const useStyles = createUseStyles<string, { open: boolean }>((theme: Theme) => ({
  outer: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  main: {
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  header: {
    height: 70,
    maxHeight: 70,
    padding: '20px 20px 10px 20px',
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
  },

  '@global': {
    a: {
      color: theme.palette.action.active,
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.7,
      },
      '&.flat': {
        color: theme.palette.common.black,
      },
    },
  },
}));

const AppLayout: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(true);
  const styles = useStyles({ open });

  const handleToggleOpen = (force?: boolean) => {
    setOpen(typeof force === 'boolean' ? force : !open);
  };

  return (
    <Box className={styles.outer}>
      <Box className={styles.content}>
        <AppSidebar open={open} onToggleOpen={handleToggleOpen} />
        <Box
          component="main"
          className={styles.main}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box className={styles.header}>
            <OrgLogo />
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
