import { FC, useState, ReactNode, MouseEvent } from 'react';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, ListItemIcon, PopoverOrigin } from '@mui/material';
import Icon, { IconType } from '../icon';
import { createUseStyles } from 'react-jss';
import MenuHeader from './header';
import classNames from 'classnames';

export type MenuItemConfig = {
  id: string;
  label: ReactNode;
  onClick: () => void;
  icon?: IconType;
};

interface Props {
  trigger: ReactNode;
  menuId?: string;
  menuItems?: MenuItemConfig[];
  children?: ReactNode;
  className?: string;
  title?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  hideClose?: boolean;
  openOnHover?: boolean;
}

const useStyles = createUseStyles({
  trigger: {
    cursor: 'pointer',
    display: 'inline-block',
  },
  menu: {
    padding: '2px 10px',
    minWidth: 300,
  },
});

const Menu: FC<Props> = ({
  trigger,
  menuId,
  menuItems = [],
  children,
  className = '',
  title,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  hideClose = false,
  openOnHover = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (event: MouseEvent<HTMLUListElement>) => {
    if (!openOnHover) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const styles = useStyles();

  let MenuComp = children;

  if (menuItems.length) {
    MenuComp = menuItems.map(({ id, label, onClick, icon }) => {
      const hasIcon = !!icon;
      return (
        <MenuItem key={id} onClick={onClick}>
          {hasIcon && (
            <ListItemIcon>
              <Icon name={icon as IconType} />
            </ListItemIcon>
          )}
          {label}
        </MenuItem>
      );
    });
  }

  const hasTitle = Boolean(title);
  const compClass = classNames(styles.menu, className);

  return (
    <Box>
      <span
        className={styles.trigger}
        id={menuId}
        role="button"
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        {trigger}
      </span>
      <MuiMenu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': menuId,
        }}
        slotProps={{
          paper: { className: compClass, elevation: 1 },
        }}
      >
        {hasTitle && <MenuHeader title={title!} onClose={handleClose} hideClose={hideClose} />}
        {MenuComp}
      </MuiMenu>
    </Box>
  );
};

export default Menu;
