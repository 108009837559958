import { Box, Stack } from '@mui/material';
import { FC, ReactNode, RefObject, useCallback, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import UserBadge from '@/components/user-badge';
import useApp from '@/hooks/use-app.hook';
import {
  ThreadMessage as ThreadMessageType,
  ThreadMessage as ThreadMessageModel,
  ThreadRole,
} from '@/lib/services/thread.service';
import ThreadMessage from './thread-message';
import LoaderDots from '@/components/loader-dots';
import useThread from '@/hooks/use-thread.hook';
import useToast from '@/hooks/use-toast.hook';
import { ShortcutMessage } from '@/lib/models/shortcut.model';
import Icon from '@/components/icon';
import { MessageControlInstance } from './message-control';
import OrgBadge from '@/components/org-badge';
import ThreadLocked from './thread-locked';
import { useScrollToBottom } from 'react-scroll-to-bottom';

interface Props {
  messageControlRef: RefObject<MessageControlInstance>;
}

const useStyles = createUseStyles({
  spacer: {
    height: 40,
    minHeight: 40,
    width: '100%',
  },
});

const ThreadList: FC<Props> = ({ messageControlRef }) => {
  const { user, branding } = useApp();
  const {
    sendMessage,
    removeDeckMessage,
    sendDeckMessage,
    setWorking,
    thread,
    messageStatus,
    streamContent,
    loadState,
  } = useThread();
  const { errorToast } = useToast();
  const styles = useStyles();
  const scrollToBottom = useScrollToBottom();

  const { messages, context: threadContext, locked } = thread;

  const systemDeckMessage: ThreadMessageType = useMemo(() => {
    const message: ThreadMessageType = {
      id: 'system-deck-message',
      threadId: thread.id,
      role: 'system' as ThreadRole,
      content: '',
      createdAt: new Date(),
      onDeck: true,
    };

    if (messageStatus === 'stream' && streamContent) {
      message.content = streamContent;
    } else {
      message.component = (
        <Box pt={2}>
          <LoaderDots />
        </Box>
      );
    }

    return message;
  }, [thread.id, streamContent, messageStatus]);

  const handleScrollToBottom = useCallback(() => {
    scrollToBottom({ behavior: 'smooth' });
  }, [scrollToBottom]);

  const handleRegenerate = useCallback(
    (message: ThreadMessageModel) => {
      const { messages = [], locked } = thread;

      if (locked) {
        return;
      }

      const messageIndex = messages.findIndex(({ id }) => id === message.id);
      let priorUserMessage: ThreadMessageType | null = null;

      if (messageIndex > 0) {
        const priorMessage = messages[messageIndex - 1];
        if (priorMessage?.role === 'user') {
          priorUserMessage = priorMessage;
        }
      }

      if (!priorUserMessage) {
        errorToast('Unable to find message');
        return;
      }

      const { content, shortcut } = priorUserMessage;

      sendMessage(content, shortcut);
    },
    [thread, errorToast, sendMessage]
  );

  const handleSendShortcut = useCallback(
    (message: ThreadMessageType, shortcut: ShortcutMessage) => {
      if (thread.locked) {
        return;
      }
      sendDeckMessage({ ...message, shortcut });
    },
    [sendDeckMessage, thread.locked]
  );

  const handleRemoveShortcut = useCallback(
    (messageId: string) => {
      removeDeckMessage(messageId);
    },
    [removeDeckMessage]
  );

  // if the onChange handler of any of the shortcut form inputs was called, the form is dirty and
  // should not be replaced by another shortcut
  const handleChangeShortcut = () => {
    setWorking(true);
  };

  const handleEdit = useCallback(
    (message: ThreadMessageType) => {
      if (thread.locked) {
        return;
      }

      const { content, shortcut } = message;

      if (shortcut) {
        // @todo reload shortcut
      }

      messageControlRef.current?.loadContent(content);
    },
    [messageControlRef, thread.locked]
  );

  useEffect(() => {
    const hasOnDeck = thread.messages.some(({ onDeck }) => onDeck);
    if (hasOnDeck) {
      handleScrollToBottom();
    }
  }, [thread, handleScrollToBottom]);

  // there is a brief fade-out-in effect when a thread loads, so wait for the fade before attempting to tail
  useEffect(() => {
    if (loadState === 'loaded') {
      setTimeout(() => {
        handleScrollToBottom();
      }, 0);
    }
  }, [handleScrollToBottom, loadState]);

  const showSystemDeckMessage = ['stream', 'send'].includes(messageStatus);

  return (
    <Stack width="100%" height="100%" maxHeight="100%" maxWidth="100%" gap={5}>
      {messages.map((message) => {
        const { id, role, shortcut } = message;

        let BadgeEl: ReactNode = null;
        let roleTitle = '';

        if (role === 'user') {
          BadgeEl = <UserBadge user={user!} />;
          roleTitle = 'You';

          if (shortcut) {
            BadgeEl = <Icon name="shortcut" size="large" />;
            roleTitle = `Shortcut: ${shortcut.name}`;
          }
        } else {
          BadgeEl = <OrgBadge />;
          roleTitle = branding.badgeTitle;
        }

        return (
          <ThreadMessage
            key={`message-${id}`}
            BadgeEl={BadgeEl}
            message={message}
            roleTitle={roleTitle}
            onRegenerate={handleRegenerate}
            onSendShortcut={(shortcut) => handleSendShortcut(message, shortcut)}
            onChangeShortcut={handleChangeShortcut}
            onRemove={() => handleRemoveShortcut(id!)}
            onEdit={handleEdit}
            threadContext={threadContext}
            threadLocked={locked}
          />
        );
      })}
      {showSystemDeckMessage && (
        <ThreadMessage
          BadgeEl={<OrgBadge />}
          message={systemDeckMessage}
          roleTitle={branding.badgeTitle}
          hideToolbar
        />
      )}
      {locked && <ThreadLocked />}
      <Box className={styles.spacer}></Box>
    </Stack>
  );
};

export default ThreadList;
