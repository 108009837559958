import { findOne, getOidParam } from './atlas-data-api.service';
import { UserPolicyView } from '../views/user-policy.view';
import { AuthProvider } from './auth.service';

const COLLECTION = 'userPolicyView';

export const isPortalEnabled = async (): Promise<boolean> => {
  const response = await findOne(COLLECTION, {
    filter: { _id: getOidParam(AuthProvider.spUserId) },
  });
  if (!response) {
    return false;
  }
  const userPolicy = new UserPolicyView(response);

  return userPolicy.portalEnabled;
};
