import { FC } from 'react';
import JpgImage from '@/assets/file-icons/icon-jpg.svg';
import Mp4Image from '@/assets/file-icons/icon-mp4.svg';
import PdfImage from '@/assets/file-icons/icon-pdf.svg';
import PngImage from '@/assets/file-icons/icon-png.svg';
import SvgImage from '@/assets/file-icons/icon-svg.svg';
import OtherImage from '@/assets/file-icons/icon-other.svg';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface Props {
  extension: string;
  size?: 'small' | 'medium';
}

type FileExtension = 'jpeg' | 'jpg' | 'mp4' | 'pdf' | 'png' | 'svg' | 'unknown';

const ExtensionImages: Record<FileExtension, string> = {
  jpeg: JpgImage,
  jpg: JpgImage,
  mp4: Mp4Image,
  pdf: PdfImage,
  png: PngImage,
  svg: SvgImage,
  unknown: OtherImage,
};

const useStyles = createUseStyles({
  fileIcon: {
    width: 40,
    '&.small': {
      width: 30,
    },
  },
});

const FileIcon: FC<Props> = ({ extension, size = 'medium' }) => {
  const validExtension = Object.keys(ExtensionImages).includes(extension) ? extension : 'unknown';
  const imageSource = ExtensionImages[validExtension as FileExtension];
  const styles = useStyles();
  const compClass = classNames(styles.fileIcon, size);

  return <img src={imageSource} className={compClass} />;
};

export default FileIcon;
