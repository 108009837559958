import FlatButton from '@/components/flat-button';
import OrgLogo from '@/components/org-logo';
import Text from '@/components/text';
import useAppData from '@/hooks/use-app-data.hook';
import useToast from '@/hooks/use-toast.hook';
import { LoadState } from '@/lib/helpers';
import { OrgBrandingModel } from '@/lib/models/org-branding.model';
import { getNeedsWelcomeMessage, setNeedsWelcomeMessage } from '@/lib/services/org.service';
import { openNewTab } from '@/lib/url-helpers';
import { Box, Stack, Theme } from '@mui/material';
import copy from 'copy-to-clipboard';
import { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  onClose: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.common.white,
  },
  welcomeMessage: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 24,
    '& .footer': {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      display: 'flex',
      alignItems: 'center',
      marginTop: 16,
      paddingTop: 24,
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));

const WelcomeMessage: FC<Props> = ({ onClose }) => {
  const styles = useStyles();
  const [loadState, setLoadState] = useState<LoadState>('unloaded');
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const { toast } = useToast();

  /*
   * Note that we are using app data here and NOT app context branding. This is to ensure
   * the welcome message is shown, even if the org does not have valid custom branding.
   */
  const { data: branding } = useAppData<OrgBrandingModel>('org-branding');

  // @todo user manually loads a thread should clear this
  useEffect(() => {
    if (loadState !== 'unloaded' || !branding) {
      return;
    }

    setLoadState('loading');

    const { portalWelcome } = branding;

    getNeedsWelcomeMessage(portalWelcome).then((showMessage) => {
      if (!showMessage) {
        setLoadState('loaded');
        return;
      }

      setShowWelcomeMessage(true);
      setNeedsWelcomeMessage(portalWelcome);
      setLoadState('loaded');
    });
  }, [loadState, branding]);

  if (loadState !== 'loaded' || !showWelcomeMessage) {
    return;
  }

  const { portalWelcome, supportEmail } = branding || new OrgBrandingModel();
  const hasLearnMoreLink = !!portalWelcome.learnMoreLink;
  const hasSupportEmail = !!supportEmail;

  const handleCloseWelcome = () => {
    setShowWelcomeMessage(false);
    onClose();
  };

  const handleLearnMore = () => {
    openNewTab(portalWelcome.learnMoreLink);
  };

  const handleContactSupport = () => {
    copy(supportEmail);
    toast(`Support email address copied to clipboard`);
  };

  return (
    <Stack className={styles.overlay} gap={3} alignItems="center" justifyContent="center">
      <Stack width="50%" gap={3} className={styles.welcomeMessage} alignItems="center">
        <OrgLogo />
        <Box>
          <Text bold size="large">
            {portalWelcome.title || 'Welcome to the Portal'}
          </Text>
        </Box>
        <Box>
          <Text>{portalWelcome.message || 'What would you like to get done today?'}</Text>
        </Box>
        <Box className="footer">
          <Box display="flex" alignItems="center" gap={3}>
            {hasLearnMoreLink && (
              <FlatButton icon="open-tab" onClick={handleLearnMore} label="Learn More" />
            )}
            {hasSupportEmail && (
              <FlatButton
                icon="contact-support"
                onClick={handleContactSupport}
                label="Contact Support"
              />
            )}
          </Box>
          <FlatButton label="Close" onClick={handleCloseWelcome} color="error" icon="close" />
        </Box>
      </Stack>
    </Stack>
  );
};

export default WelcomeMessage;
