import { UseQueryResult } from 'react-query';

export type QueryHelper = {
  hasQuery: boolean;
  showLoader: boolean;
  showQueryError: boolean;
  showChildren: boolean;
};

const useQueryHelper = (query?: UseQueryResult, loading = false): QueryHelper => {
  const hasQuery = Boolean(query);
  let showLoader = loading;
  let showQueryError = false;
  let showChildren = true;

  if (query) {
    const { isSuccess = true, isError = false } = query || {};
    showLoader = loading || (hasQuery && !isSuccess && !isError);
    showQueryError = hasQuery && isError;
    showChildren = !hasQuery || isSuccess;
  }

  return {
    hasQuery,
    showLoader,
    showQueryError,
    showChildren,
  };
};

export default useQueryHelper;
