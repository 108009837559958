import { FC, useEffect } from 'react';
import { Outlet } from '@tanstack/react-router';
import { Box, Theme } from '@mui/material';
import { useDocumentTitle } from 'usehooks-ts';

import { createUseStyles } from 'react-jss';
import useApp from './hooks/use-app.hook';
import ModalRoot from './contexts/modal/modal-root';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import useModal from './hooks/use-modal.hook';
import useFavicon from './hooks/use-favicon';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    margin: '15px 0px',
    flexGrow: 1,
    maxHeight: 'calc(100% - 94px)',
  },
  '@global': {
    a: {
      color: theme.palette.action.active,
      textDecoration: 'none',
      '&:hover': {
        opacity: 0.7,
      },
      '&.flat': {
        color: theme.palette.common.black,
      },
    },
    '& .notistack-SnackbarContainer': {
      '& .notistack-MuiContent.notistack-MuiContent-info': {
        backgroundColor: theme.palette.info.main,
      },
      '& .notistack-MuiContent.notistack-MuiContent-warning': {
        backgroundColor: theme.palette.warning.main,
      },
    },
  },
}));

const App: FC = () => {
  const styles = useStyles();
  const {
    branding,
    pageTitle: { plain },
  } = useApp();
  const hasPageTitle = Boolean(plain);

  const documentTitle = branding?.orgName;
  const favicon = branding?.favicon || '/favicon-blank.png';

  useDocumentTitle(`${documentTitle}${hasPageTitle ? ' |' : ''} ${plain}`);
  useFavicon(favicon);

  const queryClient = useQueryClient();
  const { openModal } = useModal();

  // BSOD for API errors
  useEffect(() => {
    const queryCache = queryClient.getQueryCache();
    const unsubscribe = queryCache.subscribe(() => {
      const errorQuery = queryCache.getAll().find((query) => query.state.error);

      if (!errorQuery) {
        return;
      }

      let error = 'There was a service error';
      try {
        const { response, code } = errorQuery.state.error as AxiosError;

        if (response?.status === 401) {
          error = 'Please reload the page';
        } else if (code === 'ECONNABORTED') {
          error = 'There was a network error';
        }
      } catch {
        console.error('unexpected query format error', errorQuery);
      }

      openModal('app-error', { error });
      unsubscribe();
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient, openModal]);

  return (
    <Box className={styles.root}>
      <Outlet />
      <ModalRoot />
    </Box>
  );
};

export default App;
