import { Alert, AlertColor } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  level?: AlertColor;
  message: ReactNode;
  size?: 'medium' | 'small';
  withIcon?: boolean;
}

const useStyles = createUseStyles({
  toast: {
    '&.small': {
      padding: '0px 12px',
    },
  },
});

const InlineToast: FC<Props> = ({ level = 'info', message, size = 'medium', withIcon = true }) => {
  const styles = useStyles();
  const compClass = classNames(styles.toast, size);
  return (
    <Alert className={compClass} severity={level} icon={withIcon === false ? false : undefined}>
      {message}
    </Alert>
  );
};

export default InlineToast;
