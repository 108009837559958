import { md5 } from 'hash-wasm';
import { get as getLocal, set as setLocal, remove as removeLocal } from '../local-storage';
import { findOne, getOrgFilter } from './atlas-data-api.service';
import { OrgBrandingModel } from '../models/org-branding.model';
import { JsonObject } from '../helpers';
import { getCookie, setCookie } from '../cookies';

const COLLECTION = 'orgs';

const ORG_BRANDING_CACHE_KEY = 'portal-org-branding';

export type PortalWelcome = {
  title: string;
  message: string;
  learnMoreLink: string;
};

export const getOrgBranding = async (): Promise<OrgBrandingModel | null> => {
  const { orgId } = getOrgFilter();

  const params = {
    filter: {
      _id: orgId,
    },
    projection: {
      name: 1,
      branding: 1,
    },
  };

  const response = await findOne(COLLECTION, params);

  return response
    ? new OrgBrandingModel({ ...(response.branding as JsonObject), orgName: response.name })
    : null;
};

const getWelcomeMessageKey = async (portalWelcome: PortalWelcome) => {
  const { title, message, learnMoreLink } = portalWelcome;
  return md5([title, message, learnMoreLink].join('|')).then((result) => `pwm-${result}`);
};

// Does the user need to see the welcome message?
export const getNeedsWelcomeMessage = async (portalWelcome: PortalWelcome): Promise<boolean> => {
  if (!portalWelcome.message) {
    return false;
  }
  const key = await getWelcomeMessageKey(portalWelcome);
  const welcomeMessageCookie = getCookie(key);
  return !welcomeMessageCookie;
};

export const setNeedsWelcomeMessage = async (portalWelcome: PortalWelcome) => {
  return getWelcomeMessageKey(portalWelcome).then((key) => {
    setCookie(key, '1');
  });
};

export const getCachedOrgBranding = (): OrgBrandingModel | null => {
  const brandingJson = getLocal(ORG_BRANDING_CACHE_KEY, true) as JsonObject;

  if (!brandingJson) {
    return null;
  }

  return new OrgBrandingModel(brandingJson);
};

export const setCachedOrgBranding = (orgBranding: OrgBrandingModel) => {
  setLocal(ORG_BRANDING_CACHE_KEY, orgBranding._json);
};

export const deleteCachedOrgBranding = () => {
  removeLocal(ORG_BRANDING_CACHE_KEY);
};
