import { FC, useState } from 'react';
import Icon, { IconColor, IconType, Props } from './';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useStyles = createUseStyles({
  done: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});

const DoneIcon: FC<Props> = (props) => {
  const styles = useStyles();
  const { name, color = '', onClick, className } = props;
  const [config, setConfig] = useState<{ name: IconType; color?: IconColor }>({
    name,
    color: color || undefined,
  });
  const compClass = classNames(className, config.name, config.name === 'done' ? styles.done : '');

  const handleClick = () => {
    onClick && onClick();

    setConfig({ name: 'done', color: 'success' });
    setTimeout(() => {
      setConfig({ name, color: color || undefined });
    }, 3000);
  };

  return (
    <Icon
      {...props}
      className={compClass}
      name={config.name}
      color={config.color}
      onClick={handleClick}
    />
  );
};

export default DoneIcon;
