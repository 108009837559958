import { Theme, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  text: {
    '&.inline': {
      display: 'inline-block',
    },
    '&.bold': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.italic': {
      fontStyle: 'italic',
    },
    '&.grey': {
      color: theme.palette.grey[500],
    },
    '&.active': {
      color: theme.palette.action.active,
    },
    fontSize: theme.typography.body1.fontSize,
    '&.x-small': {
      fontSize: theme.typography.caption.fontSize,
    },
    '&.small': {
      fontSize: theme.typography.body2.fontSize,
    },
    '&.large': {
      fontSize: theme.typography.h6.fontSize,
    },
    '&.x-large': {
      fontSize: theme.typography.h5.fontSize,
    },
    '&.dotdot': {
      maxWidth: '100%',
      overflow: 'hidden',
      display: 'inline-block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  size?: 'x-large' | 'large' | 'medium' | 'small' | 'x-small';
  bold?: boolean;
  italic?: boolean;
  color?: 'grey' | 'black' | 'active';
  dotdot?: boolean;
  inline?: boolean;
  className?: string;
}

const Text: FC<Props> = ({
  size = 'medium',
  children,
  noWrap = false,
  bold = false,
  italic = false,
  color = 'black',
  dotdot = false,
  inline = false,
  className = '',
}) => {
  const styles = useStyles();
  const component = size === 'x-large' ? 'h1' : 'div';
  const compClass = classNames(styles.text, size, color, className, {
    bold,
    dotdot,
    italic,
    inline,
  });

  return (
    <Typography component={component} className={compClass} color="inherit" noWrap={noWrap}>
      {children}
    </Typography>
  );
};

export default Text;
