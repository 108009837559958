import { JsonObject } from '../helpers';

export class NosqlView {
  id: string | null;

  constructor(props: JsonObject = {}) {
    const data = props as Record<string, string>;
    this.id = data._id || data.id || null;
  }
}
