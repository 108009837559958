import { Dialog, DialogContent, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import useModal from '../../hooks/use-modal.hook';
import { createUseStyles } from 'react-jss';
import { UseQueryResult } from 'react-query';
import Loader from '../loader';
import useQueryHelper from '@/hooks/use-query-helper';

const useStyles = createUseStyles<string, { width: number }>((theme: Theme) => ({
  modal: {
    '& .MuiDialogTitle-root': {
      backgroundColor: theme.palette.grey[200],
      padding: '8px 12px',
      marginBottom: 12,
    },
    '& .MuiDialogActions-root': {
      marginTop: 50,
      padding: '20px 12px 12px 12px',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      gap: 8,
    },
    '& .MuiDialogContent-root': {
      padding: '8px 12px',
    },
  },
  backdrop: {
    backgroundColor: 'rgb(255 255 255 / 40%)',
  },
  paper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 3,
    minWidth: ({ width }) => width,
  },
}));

interface Props {
  children: ReactNode;
  preventClose?: boolean;
  width?: number;
  query?: UseQueryResult;
}

export const Modal: FC<Props> = ({ children, preventClose = false, width = 400, query }) => {
  const { closeModal } = useModal();
  const handleClose = (_event: MouseEvent, reason: string) => {
    if (preventClose && reason === 'backdropClick') {
      return;
    }
    closeModal();
  };
  const styles = useStyles({ width });

  const { showLoader } = useQueryHelper(query);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={styles.modal}
      disableEscapeKeyDown={preventClose}
      PaperProps={{ className: styles.paper, elevation: 0, square: true }}
      slotProps={{ backdrop: { className: styles.backdrop } }}
    >
      {showLoader && (
        <DialogContent className={styles.loader}>
          <Loader />
        </DialogContent>
      )}
      {!showLoader && children}
    </Dialog>
  );
};

export default Modal;
