import { FC } from 'react';
import { Box, Stack, Theme } from '@mui/material';
import { getFirstLastName } from '../../lib/helpers';
import { createUseStyles } from 'react-jss';
import Menu from '../menu';
import UserBadge from '../user-badge';
import Text from '../text';
import Button from '../button';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles((theme: Theme) => ({
  userMenu: {
    backgroundColor: '#5b5b5b',
    color: theme.palette.common.white,
  },
  email: {
    color: '#cacaca',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const UserMenu: FC = () => {
  const styles = useStyles();
  const { user, signOut } = useApp();
  const { displayName, email } = user!;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasName = Boolean(first) || Boolean(last);

  let NameEl = <Text size="large">{email}</Text>;

  if (hasName) {
    NameEl = (
      <Stack gap={0.5}>
        <Text size="large">{[first, last].join(' ')}</Text>
        <Text color="grey" className={styles.email}>
          {email}
        </Text>
      </Stack>
    );
  }

  const handleSignOut = () => {
    signOut();
  };

  const TriggerEl = (
    <Box py={1}>
      <UserBadge user={user!} />
    </Box>
  );

  return (
    <Box>
      <Menu
        trigger={TriggerEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={styles.userMenu}
      >
        {NameEl}
        <Box className={styles.footer} display="flex" justifyContent="flex-end">
          <Button label="Sign Out" onClick={handleSignOut} size="small" />
        </Box>
      </Menu>
    </Box>
  );
};

export default UserMenu;
