import { IconColor, IconType } from '@/components/icon';
import { ApiFileResponse } from '@/lib/services/index';
import { FileWithPath } from 'react-dropzone';

export type FileStatus = 'stage' | 'error' | 'upload' | 'parse' | 'complete';

export const FileStatusLabels: Record<
  FileStatus,
  { label: string; icon: IconType; iconColor?: IconColor }
> = {
  stage: { label: 'Pending', icon: 'pending' },
  error: { label: 'Error', icon: 'error', iconColor: 'error' },
  upload: { label: 'Uploading', icon: 'cloud-upload' },
  parse: { label: 'Parsing', icon: 'build-circle' },
  complete: { label: 'Available as context', icon: 'done', iconColor: 'success' },
};

export type UploadControl = {
  error: string;
  progress: number | undefined;
  controller: AbortController | undefined;
  response: ApiFileResponse | undefined;
};

export type FileWithMeta = FileWithPath & {
  id: string;
  status: FileStatus;
  error: string;
  upload: UploadControl;
};

export const getFileStatus = (file: FileWithMeta): FileStatus => {
  const { error: fileError, upload } = file;
  const { error: uploadError, progress, response, controller } = upload;

  const hasError = !!fileError || !!uploadError;
  const hasUpload = !!response;

  if (hasUpload) {
    return 'complete';
  }

  if (hasError) {
    return 'error';
  }

  if (typeof progress === 'number' && controller) {
    return (progress || 0) < 0.7 ? 'upload' : 'parse';
  }

  return 'stage';
};

export const cancelUpload = (file: FileWithMeta): FileWithMeta => {
  if (!['parse', 'upload'].includes(getFileStatus(file))) {
    return file;
  }

  file.upload.controller?.abort();

  file.upload = {
    error: '',
    progress: undefined,
    controller: undefined,
    response: undefined,
  };

  return file;
};
