import MuiCard from '@mui/material/Card';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  className?: string;
}

const useStyles = createUseStyles({
  card: {
    padding: 10,
  },
});

const Card: FC<Props> = ({ children, className = '' }) => {
  const styles = useStyles();
  const compClass = classNames(styles.card, className);
  return <MuiCard className={compClass}>{children}</MuiCard>;
};

export default Card;
