import { ShortcutModel, ShortcutValue } from '../models/shortcut.model';
import { findAll, findOne, getOidParam } from './atlas-data-api.service';

const COLLECTION = 'shortcuts';

export const getShortcuts = async (): Promise<ShortcutModel[]> => {
  const filter = {
    orgId: 'global',
  };

  const response = await findAll(COLLECTION, filter, 0, 200, { _id: 1 });

  if (!response?.length) {
    return [];
  }

  return response.map((data) => new ShortcutModel(data));
};

export const getShortcutById = async (shortcutId: string): Promise<ShortcutModel | null> => {
  const response = await findOne(COLLECTION, { id: getOidParam(shortcutId) });

  return response ? new ShortcutModel(response) : null;
};

export const isShortcutValueEmpty = (value: ShortcutValue): boolean => {
  const compValue = Array.isArray(value) ? value : [value];
  return compValue.every((val) => !String(val).trim());
};
