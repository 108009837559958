import Icon from '@/components/icon';
import Menu from '@/components/menu';
import Text from '@/components/text';
import { Citation } from '@/lib/models/user-event.model';
import { ThreadContext, ThreadDocument } from '@/lib/services/thread.service';
import { openNewTab } from '@/lib/url-helpers';
import { Box, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  threadContext: ThreadContext;
  citations: Citation[];
}

const useStyles = createUseStyles((theme: Theme) => ({
  contextMenu: {
    backgroundColor: '#5b5b5b',
    color: theme.palette.common.white,
    '& .button:hover': {
      color: theme.palette.common.white,
      opacity: 0.9,
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const MessageContextMenu: FC<Props> = ({ citations, threadContext }) => {
  const styles = useStyles();

  const TriggerEl = <Icon name="article" size="small" />;

  return (
    <Menu
      trigger={TriggerEl}
      title="Cited Context Source(s)"
      anchorOrigin={{ vertical: 20, horizontal: 2 }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      className={styles.contextMenu}
      hideClose
      openOnHover
    >
      <Box maxWidth={500}>
        <Stack gap={0.5} justifyContent="center" maxWidth="100%">
          {citations.map(({ citationId, messageIndex }) => {
            const source = threadContext.get(messageIndex);

            if (!source) {
              console.warn('missing citation', messageIndex, threadContext);
              return null;
            }

            const { name } = source;
            const docUrl = (source as ThreadDocument).url;
            const hasUrl = !!docUrl;

            return (
              <Stack
                direction="row"
                justifyContent="flex-start"
                gap={1}
                key={citationId}
                maxWidth="100%"
              >
                <Box>[{citationId}]</Box>
                <Box maxWidth="85%">
                  <Text dotdot>{name}</Text>
                </Box>
                {hasUrl && (
                  <Box>
                    <Icon name="open-tab" onClick={() => openNewTab(docUrl)} />{' '}
                  </Box>
                )}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </Menu>
  );
};

export default MessageContextMenu;
