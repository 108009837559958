import { Box, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Text from '../text';
import Icon from '../icon';

interface Props {
  title: string;
  onClose: () => void;
  hideClose?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    paddingBottom: 5,
    marginBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const MenuHeader: FC<Props> = ({ title, onClose, hideClose = false }) => {
  const styles = useStyles();
  return (
    <Stack
      className={styles.header}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Text bold>{title}</Text>
      </Box>
      {!hideClose && (
        <Box>
          <Icon name="close" onClick={onClose} />
        </Box>
      )}
    </Stack>
  );
};

export default MenuHeader;
