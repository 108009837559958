import Select from '@/components/form/select';
import SelectMulti from '@/components/form/select-multi';
import Switch from '@/components/form/switch';
import TextInput from '@/components/form/text-input';
import { UiOption } from '@/lib/helpers';
import { ShortcutInputType, ShortcutValue } from '@/lib/models/shortcut.model';
import { Box } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  inputType: ShortcutInputType;
  name: string;
  value: ShortcutValue;
  options?: UiOption[];
  onChange: (value: ShortcutValue) => void;
  onEnter: () => void;
  readonly: boolean;
  autoFocus: boolean;
}

const useStyles = createUseStyles({
  switch: {
    padding: '18px 0px',
  },
});

const ShortcutInput: FC<Props> = ({
  inputType,
  name,
  value,
  onChange,
  onEnter,
  readonly,
  options = [],
  autoFocus,
}) => {
  const styles = useStyles();

  switch (inputType) {
    default:
    case 'text':
    case 'textarea': {
      const isTextarea = inputType === 'textarea';
      return (
        <TextInput
          name={name}
          value={!value ? String(value).trim() : (value as string)}
          onChange={onChange}
          onEnter={isTextarea ? undefined : onEnter}
          autoFocus={autoFocus}
          readonly={readonly}
          type={inputType}
          rows={isTextarea ? 4 : undefined}
        />
      );
    }

    case 'select':
      return (
        <Select
          name={name}
          value={value as string}
          options={options}
          onChange={onChange}
          readonly={readonly}
          autoFocus={autoFocus}
        />
      );

    case 'select-multi': {
      const handleChange = (options: UiOption[]) => onChange(options.map(({ value }) => value));

      return (
        <SelectMulti
          name={name}
          value={value as string[]}
          options={options}
          onChange={handleChange}
          readonly={readonly}
          autoFocus={autoFocus}
        />
      );
    }

    case 'switch': {
      const compOptions =
        options?.length === 2
          ? options
          : [
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ];

      const { label } = compOptions[0];
      const { label: endLabel } = compOptions[1];

      const handleChange = (checked: boolean) => {
        const optIndex = checked ? 1 : 0;
        return onChange(compOptions[optIndex].value);
      };

      return (
        <Box className={styles.switch}>
          <Switch
            name={name}
            checked={value === compOptions[1].value}
            onChange={handleChange}
            autoFocus={autoFocus}
            readonly={readonly}
            label={label}
            endLabel={endLabel}
          />
        </Box>
      );
    }
  }
};

export default ShortcutInput;
