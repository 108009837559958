import { VariantType } from 'notistack';
import { createContext } from 'react';

export const AUTO_HIDE_DURATION = 6000;

export interface ThreadToastMessage {
  content: string;
  variant: VariantType;
}

export const DefaultMessage: ThreadToastMessage = {
  content: '',
  variant: 'info',
};

export interface ThreadToastSettings {
  message: ThreadToastMessage;
  toast: (content: string, toastType?: VariantType) => void;
  clear: () => void;
  open: boolean;
}

export const ThreadToastContext = createContext<ThreadToastSettings>({
  message: { ...DefaultMessage },
  toast: () => null,
  clear: () => null,
  open: false,
});
