import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, LinearProgress, Stack, Theme } from '@mui/material';
import FileIcon from './file-icon';
import Text from '@/components/text';
import Tooltip from '@/components/tooltip';
import { niceFilesize } from '@/lib/helpers';
import InlineToast from '@/components/inline-toast';
import Icon from '@/components/icon';
import FlatButton from '@/components/flat-button';
import { FileStatusLabels, FileWithMeta, getFileStatus } from './helpers';

interface Props {
  file: FileWithMeta;
  onCancelUpload: (fileId: string) => void;
  onRemoveFile: (file: FileWithMeta) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  uploadFile: {
    gap: 8,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    width: 'calc(100% - 7px)',
    padding: '8px 0px 16px 8px',
    margin: '0px 7px 4px 0px',
    boxSizing: 'border-box',
    overflow: 'hidden',

    '& img': {
      display: 'block',
      width: 'auto',
      height: 40,
    },

    '& .delete-button:not(.disabled) svg': {
      opacity: 0.5,
      '&:hover': {
        opacity: 1,
      },
    },
  },
  cancelUploadButton: {
    lineHeight: '100%',
  },
  progressBar: {
    height: 6,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.action.active,
    },
  },
}));

const UploadFile: FC<Props> = ({ file, onRemoveFile, onCancelUpload }) => {
  const styles = useStyles();

  const { upload, name, size, error: fileError } = file;
  const { response: uploadFile, progress, error: uploadError } = upload;

  const status = getFileStatus(file);
  const extension = name.split('.').pop() || '';
  const hasError = Boolean(fileError) || Boolean(uploadError);
  const { summary } = uploadFile || {};
  const hasSummary = Boolean(summary);

  let safeProgress = progress || 0;
  if (['upload', 'parse'].includes(status)) {
    safeProgress = Math.min(safeProgress, 0.9); // hold progress at 90% while "parsing";
  }

  const isComplete = status === 'complete';
  const isUploading = ['upload', 'parse'].includes(status);

  const {
    label: statusLabel,
    icon: statusIcon,
    iconColor: statusIconColor = 'black',
  } = FileStatusLabels[status];

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={styles.uploadFile}
      key={name}
    >
      <FileIcon extension={extension} />

      <Stack flexGrow={1}>
        <Text size="small" dotdot>
          <Tooltip title={name}>{name}</Tooltip>
        </Text>
        <Text size="x-small" color="grey">
          {niceFilesize(size)}
        </Text>
        <Box mt={2}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Icon name={statusIcon} color={statusIconColor} />
            <Text size="small">{String(statusLabel).toUpperCase()}</Text>
          </Stack>
          <Box>
            <Box mt={1}>
              {isUploading && (
                <Stack direction="row" gap={1} alignItems="center">
                  <Box flexGrow={1}>
                    <LinearProgress
                      className={styles.progressBar}
                      variant="determinate"
                      value={safeProgress * 100}
                    />
                  </Box>
                  <Box>
                    <FlatButton
                      icon="close"
                      label="Cancel"
                      size="small"
                      className={styles.cancelUploadButton}
                      onClick={() => onCancelUpload(file.id)}
                    />
                  </Box>
                </Stack>
              )}
              {!isUploading && hasSummary && <Text size="small">{summary}</Text>}

              {!isUploading && hasError && (
                <Box mt={2}>
                  <InlineToast
                    size="small"
                    level="error"
                    message={fileError || uploadError}
                    withIcon={false}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Stack>

      {!isComplete && (
        <Icon
          name="delete"
          color="error"
          className="delete-button"
          onClick={() => onRemoveFile(file)}
          disabled={!['error', 'stage'].includes(status)}
        />
      )}
    </Stack>
  );
};

export default UploadFile;
