import { ReactNode, FC, useState, useEffect } from 'react';
import {
  AuthUser,
  getAuthNoAccessUrl,
  getAuthRedirectUrl,
  getCurrentUser,
  getFromAuthSite,
  observeTokenChange,
  signOut,
  userCan,
} from '../../lib/services/auth.service';
import { AppContext, AppSettings, BreadCrumb, DefaultOrgBranding, SpOrgBranding } from './helpers';
import { setDatadogUser } from '@/lib/datadog';
import { getAppData, prefetchAppData } from '@/lib/app-data';
import { OrgBrandingModel } from '@/lib/models/org-branding.model';
import { deleteCachedOrgBranding, setCachedOrgBranding } from '@/lib/services/org.service';
import { getUrlQueryParam } from '@/lib/url-helpers';

interface Props {
  children: ReactNode;
}

const isPublicPath = (path: string): boolean => ['/error', '/portal-disabled'].includes(path);

export const AppProvider: FC<Props> = ({ children }) => {
  // @todo consistent org settings load?
  const handleSignIn = (authUser: AuthUser): boolean => {
    setSettings((old) => ({
      ...old,
      user: authUser,
      signedIn: true,
    }));

    return true;
  };

  const [settings, setSettings] = useState<AppSettings>({
    loadState: 'unloaded',
    signedIn: false,
    signIn: handleSignIn,
    signOut,
    user: null,
    pageTitle: { plain: '' },
    setPageTitle: (plain: string, breadcrumb?: BreadCrumb | BreadCrumb[]) => {
      setSettings((old) => ({
        ...old,
        pageTitle: { plain, breadcrumb },
      }));
    },
    branding: DefaultOrgBranding,
    isDemo: !!getUrlQueryParam('demo'),
  });

  // run once on boot
  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'unloaded') {
      return;
    }

    setSettings((old) => ({
      ...old,
      loadState: 'loading',
    }));
  }, [settings]);

  // check for auth and load user and org
  useEffect(() => {
    const { loadState } = settings;
    if (loadState !== 'loading') {
      return;
    }

    // determine whether the user was redirected from auth, then remove the indicating querystring param, making it "one-time use"
    const fromAuthSite = getFromAuthSite();

    if (isPublicPath(window.location.pathname)) {
      setSettings((old) => ({
        ...old,
        user: null,
        signedIn: false,
        loadState: 'loaded',
      }));

      return;
    }

    getCurrentUser().then((user) => {
      const signedIn = Boolean(user);

      /*
       * This auth check is a duplicate of the router-defined `beforeLoad: requireAuth` check. The
       * reason for the unfortunate duplication of effort here is because I couldn't find a good way
       * to redirect to an external URL from a @tanstack/react-router beforeLoad function, or any other
       * route-guard-type function. Using a 'window.location.href' redirect method would still cause the
       * page to load briefly, which is not great UX.
       */
      if (!signedIn) {
        window.location.href = getAuthRedirectUrl(fromAuthSite);
        return;
      }

      // not an org:user or better? redirect to portal no access page
      if (!userCan(user, 'read')) {
        window.location.href = getAuthNoAccessUrl();
        return;
      }

      prefetchAppData().then(() => {
        const branding = getAppData<OrgBrandingModel>('org-branding');
        const isPortalEnabled = getAppData<boolean>('portal-policy');

        observeTokenChange();

        setDatadogUser(user!.uid, user!.email || '');

        if (!isPortalEnabled) {
          window.location.href = getAuthNoAccessUrl();
          return;
        }

        const hasBranding = !!branding?.enabled;

        if (hasBranding) {
          setCachedOrgBranding(branding);
        } else {
          deleteCachedOrgBranding();
        }

        setSettings((old) => ({
          ...old,
          user,
          signedIn,
          branding: hasBranding ? branding : SpOrgBranding,
          loadState: 'loaded',
        }));
      });
    });
  }, [settings]);

  return <AppContext.Provider value={settings}>{children}</AppContext.Provider>;
};
