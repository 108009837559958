import { FC } from 'react';
import { AuthUser, getUserDetails } from '../../lib/services/auth.service';
import { Avatar } from '@mui/material';
import Icon from '../icon';
import { createUseStyles } from 'react-jss';

interface Props {
  user: AuthUser;
  withTooltip?: boolean;
}

const useStyles = createUseStyles({
  userBadge: {
    backgroundColor: '#7ebd94',
  },
});

const UserBadge: FC<Props> = ({ user }) => {
  const styles = useStyles();

  const { hasPhoto, hasName, first, last, username, photoURL } = getUserDetails(user);
  const initials = `${first[0] || ''}${last[0] || ''}`.toUpperCase();

  return (
    <span>
      {hasPhoto && (
        <Avatar alt={username} src={photoURL || ''} imgProps={{ referrerPolicy: 'no-referrer' }} />
      )}
      {!hasPhoto && hasName && <Avatar className={styles.userBadge}>{initials}</Avatar>}
      {!hasPhoto && !hasName && (
        <Avatar className={styles.userBadge}>
          <Icon name="person" color="white" />
        </Avatar>
      )}
    </span>
  );
};

export default UserBadge;
