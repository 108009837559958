import {
  DragEvent as ReactDragEvent,
  RefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import pluralize from 'pluralize';
import { Box, Stack, Theme } from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import Button from '@/components/button';
import InlineToast from '@/components/inline-toast';
import { AxiosProgressEvent } from 'axios';
import UploadFile from './upload-file';
import { FileWithMeta, cancelUpload, getFileStatus } from './helpers';
import FlatButton from '@/components/flat-button';
import { FileSettings, uploadContextFile } from '@/lib/services/file.service';
import { cloneDeep } from 'lodash';
import MessageTray, { MessageTrayInstance } from '../message-tray';
import { getUUID } from '@/lib/helpers';
import useAppData from '@/hooks/use-app-data.hook';
import { ApiFileResponse } from '@/lib/services/api.service';

interface Props {
  threadId: string;
  onUpload?: (file: ApiFileResponse) => void;
  containerRef: RefObject<HTMLDivElement>;
  disabled: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  dropzone: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  scrollContainer: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: 10,
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    boxSizing: 'border-box',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    gap: 8,
    padding: 12,
    backgroundColor: theme.palette.grey[100],
  },
}));

export interface UploadFormInstance {
  close: () => void;
  loadFiles: (files: FileList) => void;
}

const UploadForm = forwardRef<UploadFormInstance, Props>(
  ({ threadId, onUpload, containerRef, disabled }, ref) => {
    const [files, setFiles] = useState<FileWithMeta[]>([]);
    const [showDropzone, setShowDropzone] = useState(true);
    const trayRef = useRef<MessageTrayInstance>(null);

    const styles = useStyles();

    const { data } = useAppData<FileSettings>('file-settings');
    const { maxFilesPerUpload = 0, maxFileSizeBytes = 0, allowedFileTypes = [] } = data || {};

    const handleCloseTray = () => {
      trayRef.current?.close();
    };

    const handleToggleTray = () => {
      trayRef.current?.toggle();
    };

    const handleRemoveFile = (file: FileWithMeta) => {
      const fileIndex = files.findIndex(({ id }) => id === file.id);

      if (fileIndex === -1) {
        return;
      }

      setFiles((old) => {
        const updated = cloneDeep(old);
        updated.splice(fileIndex, 1);
        if (!updated.length) {
          setShowDropzone(true);
        }

        return updated;
      });
    };

    const handleCancelAll = () => {
      setFiles((old) => cloneDeep(old).map(cancelUpload));
    };

    const handleCancelUpload = (fileId: string) => {
      setFiles((old) => {
        const updated = cloneDeep(old);
        const fileIndex = updated.findIndex(({ id }) => id === fileId);

        if (fileIndex === -1) {
          return updated;
        }

        updated[fileIndex] = cancelUpload(updated[fileIndex]);

        return updated;
      });
    };

    const handleAddFiles = () => {
      setShowDropzone(true);
    };

    const handleCancelAddFiles = () => {
      setShowDropzone(false);
    };

    const handleUploadProgress = (file: FileWithMeta, event: AxiosProgressEvent) => {
      setFiles((old) => {
        const updated = cloneDeep(old);

        const fileIndex = updated.findIndex(({ id }) => id === file.id);

        if (fileIndex === -1) {
          return old;
        }

        updated[fileIndex].upload.progress = event.progress || 0;
        return updated;
      });
    };

    const startUpload = useCallback(
      (stagedFile: FileWithMeta): boolean => {
        if (getFileStatus(stagedFile) !== 'stage') {
          return false;
        }

        uploadContextFile(
          stagedFile,
          threadId,
          (event) => handleUploadProgress(stagedFile, event),
          stagedFile.upload.controller
        ).then(({ file: uploadFile, error }) => {
          if (uploadFile?.name && !error) {
            onUpload && onUpload(uploadFile);
          }

          setFiles((old) => {
            const updated = cloneDeep(old);
            const fileIndex = updated.findIndex(({ id }) => id === stagedFile.id);

            if (fileIndex === -1) {
              return updated;
            }

            const updatedFile = updated[fileIndex];

            // upload was cancelled
            if (!uploadFile) {
              updatedFile.upload = {
                error: '',
                progress: undefined,
                controller: undefined,
                response: undefined,
              };

              // upload failed
            } else if (error) {
              updatedFile.upload.error = error;

              // upload succeeded
            } else {
              updatedFile.upload.response = uploadFile;
            }

            updated[fileIndex] = updatedFile;
            return updated;
          });
        });

        return true;
      },
      [threadId, onUpload]
    );

    const handleSendFiles = useCallback(
      (files: FileWithMeta[]) => {
        const sentFiles = cloneDeep(files);

        sentFiles.forEach((stagedFile, fileIndex) => {
          if (getFileStatus(stagedFile) !== 'stage') {
            return;
          }

          const { error, upload } = stagedFile;

          if (upload.error || error) {
            return;
          }

          stagedFile.upload = {
            error: '',
            progress: undefined,
            controller: new AbortController(),
            response: undefined,
          };

          sentFiles[fileIndex] = stagedFile;
        });

        setFiles((old) => {
          const updated = cloneDeep(old);
          sentFiles.forEach((sentFile) => {
            const fileIndex = old.findIndex(({ id }) => id === sentFile.id);
            if (fileIndex > -1) {
              updated[fileIndex] = sentFile;
            }
          });

          return updated;
        });

        setTimeout(() => {
          sentFiles.forEach(startUpload);
        }, 300);
      },
      [startUpload]
    );

    const handleDrop = useCallback(
      (acceptedFiles: FileWithPath[]) => {
        if (!acceptedFiles.length) {
          return;
        }

        const updatedFiles = cloneDeep(files);
        const newFiles: FileWithMeta[] = [];

        acceptedFiles.forEach((file, index) => {
          if (index >= maxFilesPerUpload) {
            return;
          }

          const fileWithMeta = file as FileWithMeta;

          fileWithMeta.id = getUUID();
          fileWithMeta.error = '';
          fileWithMeta.upload = {
            error: '',
            progress: undefined,
            controller: undefined,
            response: undefined,
          };

          /*
           * Note that we are checking both filesize and filetype limits here, rather than via react-dropzone handlers so that we can
           * still show the file in the tray along with a helpful error message.
           */
          if (file.size > maxFileSizeBytes) {
            fileWithMeta.error = `Please limit the filesize to a maximum of ${Math.floor(maxFileSizeBytes / 1000000)}mb`;
          }

          const extension = file.path?.split('.').pop();
          if (
            !extension ||
            !allowedFileTypes.find(({ extension: allowedExt }) => allowedExt === extension)
          ) {
            fileWithMeta.error = `The specified file type is not supported`;
          }

          updatedFiles.unshift(fileWithMeta);
          newFiles.push(fileWithMeta);
        });

        setFiles(updatedFiles);

        setShowDropzone(false);

        handleSendFiles(newFiles);
      },
      [files, allowedFileTypes, maxFilesPerUpload, maxFileSizeBytes, handleSendFiles]
    );

    const handleClose = () => {
      handleCloseTray();

      setTimeout(() => {
        if (files.length) {
          setShowDropzone(false);
        }
      }, 600);
    };

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: handleDrop,
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          close: () => {
            handleCloseTray();
          },
          loadFiles: (files: FileList) => {
            trayRef.current?.open();

            setTimeout(() => {
              const dataTransfer = new DataTransfer();
              Array.from(files).map((file) => dataTransfer.items.add(file));

              const dropEvent = new DragEvent('drop', { dataTransfer });
              const reactDropEvent = dropEvent as unknown as ReactDragEvent<HTMLDivElement>;

              reactDropEvent.persist = () => {
                return;
              };

              const { onDrop } = { ...getRootProps() };

              onDrop?.(reactDropEvent);
            });
          },
        };
      },
      [getRootProps]
    );

    const hasFiles = !!files.length;

    const complete = files.filter((file) => getFileStatus(file) === 'complete');
    const hasComplete = !!complete.length;
    const completeCount = complete.length;

    const staged = files.filter((file) => getFileStatus(file) === 'stage');
    const hasStaged = !!staged.length;
    const stagedCount = staged.length;

    const hasUploading = Boolean(
      files.filter((file) => ['upload', 'parse'].includes(getFileStatus(file))).length
    );

    /*
    no buttons

    more than zero files staged?
      -> reset (remove staged files)
      -> start uploads (start uploading staged files)

    all files have errors or are completed?
      -> close

    more than zero files uploading
      -> cancel uploads

  */

    return (
      <Box>
        <MessageTray containerRef={containerRef} height={350} ref={trayRef}>
          <Stack height="100%" width="100%">
            <Box flexGrow={1}>
              {showDropzone && (
                <Box {...getRootProps()} flexGrow={1} className={styles.dropzone}>
                  <input {...getInputProps()} />
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Drag and drop up to {maxFilesPerUpload} files here, or click to choose files
                  </Box>
                </Box>
              )}
              {!showDropzone && (
                <Box className={styles.scrollContainer}>
                  <Scrollbars>
                    <Stack className={styles.filesContainer} justifyContent="flex-start" gap={1}>
                      {files.map((file) => {
                        return (
                          <UploadFile
                            file={file}
                            key={`upload-file-${file.id}-${getFileStatus(file)}`}
                            onRemoveFile={handleRemoveFile}
                            onCancelUpload={handleCancelUpload}
                          />
                        );
                      })}
                    </Stack>
                  </Scrollbars>
                </Box>
              )}
            </Box>
            <Stack className={styles.footer}>
              {!hasUploading && (
                <Stack
                  flexGrow={1}
                  direction="row"
                  gap={2}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FlatButton icon="close" label="Close" size="small" onClick={handleClose} />
                  {showDropzone && hasFiles && (
                    <FlatButton
                      icon="cancel"
                      label="Cancel"
                      size="small"
                      onClick={handleCancelAddFiles}
                    />
                  )}

                  {!showDropzone && (
                    <FlatButton
                      icon="upload-file"
                      label="Add Files"
                      size="small"
                      onClick={handleAddFiles}
                    />
                  )}
                </Stack>
              )}
              {hasComplete && (
                <InlineToast
                  size="small"
                  message={`${completeCount} ${pluralize('files', completeCount)} added to context`}
                />
              )}

              {hasUploading && (
                <Button
                  label="Cancel All Uploads"
                  size="small"
                  type="cancel"
                  onClick={handleCancelAll}
                />
              )}

              {!showDropzone && hasStaged && (
                <Button
                  label={`Upload ${stagedCount} ${pluralize('file', stagedCount)}`}
                  size="small"
                  onClick={() => {
                    handleSendFiles(files);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </MessageTray>

        <Stack direction="row" gap={0.5} alignItems="center" justifyContent="flex-start">
          <FlatButton
            icon="upload-file"
            label={
              hasComplete ? `${completeCount} ${pluralize('file', completeCount)} added` : 'Upload'
            }
            onClick={handleToggleTray}
            size="small"
            disabled={disabled}
          />
        </Stack>
      </Box>
    );
  }
);

export default UploadForm;
