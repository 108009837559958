import Icon from '@/components/icon';
import InlineToast from '@/components/inline-toast';
import { Box, Stack } from '@mui/material';
import { FC } from 'react';

const ThreadLocked: FC = () => {
  const MessageEl = (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Icon name="lock" size="small" />
      <Box>The task is locked due to a policy violation. Please start a new task.</Box>
    </Stack>
  );

  return <InlineToast withIcon={false} message={MessageEl} level="error" />;
};

export default ThreadLocked;
