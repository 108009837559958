import {
  redirect,
  Navigate,
  createRootRoute,
  createRoute,
  createRouter,
} from '@tanstack/react-router';
import App from '../App';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import NotFoundPage from '../pages/not-found';
import ErrorPage from '@/pages/error';
import { AuthProvider } from '@/lib/services/auth.service';

const rootRoute = createRootRoute({
  component: App,
});

const requireAuth = () => {
  if (!AuthProvider.user) {
    throw redirect({
      to: '/login',
    });
  }
};

const excludeAuth = () => {
  if (AuthProvider.user) {
    throw redirect({
      to: '/home',
    });
  }
};

const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/',
    component: () => <Navigate to="/home" />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/home',
    component: HomePage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/home/$threadId',
    component: HomePage,
    beforeLoad: requireAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/login',
    component: LoginPage,
    beforeLoad: excludeAuth,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '/error',
    component: ErrorPage,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    path: '*',
    component: NotFoundPage,
  }),
];

const routeTree = rootRoute.addChildren(routes);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default router;
