import { FC, useEffect, useState } from 'react';
import Page from '../../components/page';
import { Box, Stack } from '@mui/material';
import Text from '@/components/text';
import Icon, { IconType } from '@/components/icon';
import PageCard from '@/components/page-card';
import { LoadState } from '@/lib/helpers';
import { addError } from '@/lib/datadog';
import { getUrlQueryParam } from '@/lib/url-helpers';

interface Props {
  error?: Error;
}

const ErrorPage: FC<Props> = ({ error }) => {
  const code = getUrlQueryParam('code'); // using tanstack useSearch was throwing an error for some reason

  const [loadState, setLoadState] = useState<LoadState>('unloaded');

  useEffect(() => {
    if (!error) {
      setLoadState('loaded');
      return;
    }

    if (loadState !== 'unloaded') {
      return;
    }

    const renderError = new Error(error.message);
    renderError.name = `ReactRenderingError`;
    renderError.stack = error.stack;
    renderError.cause = error;
    addError(renderError);

    setLoadState('loaded');
  }, [loadState, error]);

  let icon: IconType = 'network-error';
  let title = 'Unexpected Error';
  let content = 'There was an unexpected error. Please try again later.';

  switch (Number(code)) {
    case 403:
      icon = 'deny';
      title = 'Not Approved for Access';
      content =
        'Your user account is not setup for access to the Portal. Please contact IT support if you require access.';
      break;
    case 500:
      title = 'Authentication Error';
      content =
        'There was an unexpected error while authenticating your account. Please try again later.';
  }

  return (
    <Page>
      <PageCard>
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Box>
            <Icon name={icon} size="x-large" color="error" />
          </Box>
          <Box>
            <Text size="x-large">{title}</Text>
          </Box>
          <Box mt={2}>{content}</Box>
        </Stack>
      </PageCard>
    </Page>
  );
};

export default ErrorPage;
