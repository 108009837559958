import { Stack, Theme } from '@mui/material';
import { FC } from 'react';
import Icon from '../icon';
import Text from '../text';
import { createUseStyles } from 'react-jss';

interface Props {
  onClick: () => void;
}

const useStyles = createUseStyles<string, { width: number }>((theme: Theme) => ({
  newTaskButton: {
    cursor: 'pointer',
    width: '100%',
    minHeight: 70,
    boxSizing: 'border-box',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.9,
    },
    '& .MuiAvatar-root': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      width: 34,
      height: 34,
    },
  },
}));

const NewTaskButton: FC<Props> = ({ onClick }) => {
  const styles = useStyles();

  return (
    <Stack gap={0.5} role="button" onClick={onClick} className={styles.newTaskButton}>
      <Icon name="plus" background="first" />
      <Text size="small" dotdot>
        New Task
      </Text>
    </Stack>
  );
};

export default NewTaskButton;
