import { Box, Stack, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loader from '../loader';
import { createUseStyles } from 'react-jss';
import Text from '../text';
import Icon from '../icon';
import classNames from 'classnames';

export type SidebarListItem = {
  title: string;
  content: ReactNode;
  key: string;
  onClick?: () => void;
  active?: boolean;
};

interface Props {
  isLoading: boolean;
  items: SidebarListItem[];
}

const useStyles = createUseStyles((theme: Theme) => ({
  list: {
    flexGrow: 1,
    maxHeight: '100%',
    overflowX: 'hidden',
    paddingLeft: 18,
  },
  item: {
    cursor: 'pointer',
    paddingBottom: 8,
    marginBottom: 10,
    width: '100%',
    gap: 8,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '&:hover': {
      '& .title': {
        opacity: 0.7,
      },
    },
    '&:hover, &.active': {
      '& $activeIcon': {
        opacity: 1,
      },
    },
  },
  scrollContainer: {
    paddingRight: 18,
  },
  activeIcon: {
    opacity: 0,
  },
}));

const SidebarList: FC<Props> = ({ isLoading, items }) => {
  const styles = useStyles();
  return (
    <Box className={styles.list}>
      <Scrollbars>
        <Stack className={styles.scrollContainer}>
          {isLoading && <Loader />}

          {!isLoading &&
            items.map((item) => {
              const { title, content, key, onClick, active = false } = item;
              const compClass = classNames(styles.item, { active });
              return (
                <Stack key={key} className={compClass} onClick={() => onClick?.()} role="button">
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box flexGrow={1}>
                      <Text size="small" bold className="title">
                        {title}
                      </Text>
                    </Box>
                    <Box>
                      <Icon name="chevron-right" size="small" className={styles.activeIcon} />
                    </Box>
                  </Stack>

                  <Text size="small">{content}</Text>
                </Stack>
              );
            })}
        </Stack>
      </Scrollbars>
    </Box>
  );
};

export default SidebarList;
