export type IEnvironment = {
  SP_API_URL: string;
  ATLAS_DATASOURCE: string;
  ATLAS_DATABASE: string;
  SP_EDGE_API_URL: string;
  FIREBASE_API_KEY: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SERVICE: string;
  DATADOG_SITE: string;
  APP_VERSION: string;
  MODE: string;
};

const meta = import.meta.env;

const Environment: IEnvironment = Object.freeze({
  SP_API_URL: (meta.VITE_SP_API_URL as string) || '',
  ATLAS_DATASOURCE: (meta.VITE_ATLAS_DATASOURCE as string) || '',
  ATLAS_DATABASE: (meta.VITE_ATLAS_DATABASE as string) || '',
  SP_EDGE_API_URL: (meta.VITE_SP_EDGE_API_URL as string) || '',
  FIREBASE_API_KEY: (meta.VITE_FIREBASE_API_KEY as string) || '',
  DATADOG_APPLICATION_ID: (meta.VITE_DATADOG_APPLICATION_ID as string) || '',
  DATADOG_CLIENT_TOKEN: (meta.VITE_DATADOG_CLIENT_TOKEN as string) || '',
  DATADOG_SERVICE: (meta.VITE_DATADOG_SERVICE as string) || '',
  DATADOG_SITE: (meta.VITE_DATADOG_SITE as string) || '',
  APP_VERSION,
  MODE: meta.MODE,
});

export default Environment;

export const isProd = () => Environment.MODE === 'production';
