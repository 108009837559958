import Button from '@/components/button';
import Text from '@/components/text';
import { ShortcutMessage, ShortcutValue } from '@/lib/models/shortcut.model';
import { Stack, Box, Step, StepContent, StepLabel, Stepper, Theme } from '@mui/material';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ShortcutInput from './shortcut-input';
import { useMap } from 'usehooks-ts';
import Tooltip from '@/components/tooltip';
import Chip from '@/components/chip';
import { useAtBottom } from 'react-scroll-to-bottom';

interface Props {
  shortcut: ShortcutMessage;
  onSend: (shortcut: ShortcutMessage) => void;
  messageSent: boolean;
  onChange: (shortcut: ShortcutMessage) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  shortcutForm: {
    marginBottom: 10,
  },
  stepper: {
    '&.MuiStepper-root': {
      '&.sent .MuiStepContent-root': {
        '& .MuiBox-root': {
          marginBottom: 0,
        },
        '& .MuiFormControl-root': {
          marginBottom: 0,
        },
      },
    },
  },
  stepLabel: {
    '&.Mui-active': {
      color: theme.palette.common.black,
      opacity: 1,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
    },
    color: theme.palette.common.black,
    opacity: 0.6,
  },
  stepContent: {
    width: 'calc(100% - 12px)',
    boxSizing: 'border-box',
  },
  stepBlurb: {
    cursor: 'pointer',
    display: 'inline',
  },
}));

const EXPAND_ANIMATION_DURATION = 100;

const stepHasValue = (value: ShortcutValue): boolean => {
  if (!value) {
    return false;
  }

  return Array.isArray(value) ? !!value.length : !!String(value).trim();
};

const ShortcutForm: FC<Props> = ({
  shortcut,
  onSend,
  messageSent: propMessageSent = false,
  onChange,
}) => {
  const styles = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [stepValues, setStepValues] = useState<ShortcutValue[]>(shortcut.values);
  const [messageSent, setMessageSent] = useState(propMessageSent);
  const [touched, touchedActions] = useMap<number, boolean>();
  const [autoFocus, setAutoFocus] = useState(false);

  const handleSend = useCallback(() => {
    setActiveStep(-1);
    setMessageSent(true);

    setTimeout(() => {
      onSend({ ...shortcut, values: stepValues });
    }, EXPAND_ANIMATION_DURATION + 150);
  }, [shortcut, stepValues, onSend]);

  const handleGoto = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((old) => old + 1);
  };

  const handleBack = () => {
    setActiveStep((old) => old - 1);
  };

  const handleInputChange = (stepIndex: number, value: ShortcutValue) => {
    const updatedValues = cloneDeep(stepValues);
    updatedValues[stepIndex] = value;
    setStepValues(updatedValues);
    onChange({ ...shortcut, values: updatedValues });
  };

  const { instructions, steps } = shortcut;
  const hasInstructions = !!instructions;
  const compClass = classNames(styles.stepper, { sent: messageSent });

  useEffect(() => {
    if (touched.has(activeStep)) {
      return;
    }
    touchedActions.set(activeStep, true);
  }, [activeStep, touchedActions, touched]);

  /*
   * If you set autoFocus to true on load, it borks the react-scroll-to-bottom smooth scrolling, so wait
   * until context reports that we've hit the bottom of the scroll container
   */
  const atBottom = useAtBottom();
  useEffect(() => {
    if (atBottom[0]) {
      setTimeout(() => {
        setAutoFocus(true);
      }, 400);
    }
  }, [atBottom]);

  return (
    <Box className={styles.shortcutForm}>
      {hasInstructions && (
        <Box mb={3}>
          <Text>{instructions}</Text>
        </Box>
      )}
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical" className={compClass}>
          {steps.map((step, index) => {
            const { name, description, title, inputType, options = [], required = false } = step;

            const value = stepValues[index] || '';
            const validValue = !required || stepHasValue(value);

            const isActiveStep = !messageSent && index === activeStep;
            const isFirstStep = index === 0;
            const isLastStep = index === steps.length - 1;

            const isCompleted = messageSent || (!isActiveStep && touched.has(index) && validValue);
            const nextAction = isLastStep ? handleSend : handleNext;
            const hasDescription = !!description;
            const hasTitle = !!title;

            let NextButtonEl = (
              <Button
                label={isLastStep ? 'Send' : 'Continue'}
                onClick={nextAction}
                disabled={!validValue}
              />
            );

            if (!validValue) {
              NextButtonEl = <Tooltip title="This step is required">{NextButtonEl}</Tooltip>;
            }

            return (
              <Step key={name} expanded={messageSent} completed={isCompleted} active={isActiveStep}>
                <StepLabel StepIconProps={{ className: styles.stepLabel }}>
                  <Stack direction="row" justifyContent="space-between" gap={2}>
                    <Box
                      role="button"
                      className={styles.stepBlurb}
                      onClick={() => handleGoto(index)}
                    >
                      {hasTitle && !hasDescription && <Box>{title}</Box>}
                      {hasTitle && hasDescription && (
                        <Box mb={1}>
                          <Text bold>{title}</Text>
                        </Box>
                      )}
                      {hasDescription && <Box>{description}</Box>}
                    </Box>
                    {required && isActiveStep && (
                      <Box>
                        <Chip label="Required" size="small" />
                      </Box>
                    )}
                  </Stack>
                </StepLabel>
                <StepContent
                  className={styles.stepContent}
                  TransitionProps={{ timeout: EXPAND_ANIMATION_DURATION }}
                >
                  <Box mb={2} width="100%">
                    <Box width="100%" mb={2}>
                      <ShortcutInput
                        inputType={inputType}
                        name={name}
                        value={value}
                        onChange={(value) => handleInputChange(index, value)}
                        onEnter={nextAction}
                        readonly={messageSent}
                        options={options}
                        autoFocus={autoFocus}
                      />
                    </Box>
                    {!messageSent && (
                      <Stack direction="row" gap={1} alignItems="center">
                        {NextButtonEl}

                        {isActiveStep && (
                          <Button
                            onClick={handleBack}
                            label="Back"
                            type="cancel"
                            disabled={isFirstStep}
                          />
                        )}
                      </Stack>
                    )}
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
};

export default ShortcutForm;
