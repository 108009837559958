import { FC } from 'react';
import useApp from '@/hooks/use-app.hook';
import UserBadge from '../user-badge';
import { AuthUser } from '@/lib/services/auth.service';

const OrgBadge: FC = () => {
  const { branding } = useApp();

  const { portalBadge = { title: '', image: '' }, badgeTitle } = branding;
  const { image } = portalBadge;

  if (image) {
    return <img src={image} alt={badgeTitle} width={40} />;
  }

  return <UserBadge user={{ displayName: badgeTitle } as AuthUser} />;
};

export default OrgBadge;
