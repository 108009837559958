import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import {
  ThreadToastMessage,
  DefaultMessage,
  ThreadToastSettings,
  AUTO_HIDE_DURATION,
} from './helpers';
import { VariantType } from 'notistack';
import { JsTimeout } from '@/lib/helpers';

export const ThreadToastContext = createContext<ThreadToastSettings>({
  message: { ...DefaultMessage },
  toast: () => null,
  clear: () => null,
  open: false,
});

export function ThreadToastProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [message, setMessage] = useState<ThreadToastMessage>({ ...DefaultMessage });
  const [open, setOpen] = useState(false);
  const openTimeoutRef = useRef<JsTimeout>();
  const clearMessageTimeoutRef = useRef<JsTimeout>();

  const toast = useCallback((content: string, toastType?: VariantType) => {
    setMessage({ content, variant: toastType || 'warning' });

    setOpen(true);

    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current);
    }

    openTimeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, AUTO_HIDE_DURATION);
  }, []);

  const clear = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!open) {
      if (clearMessageTimeoutRef.current) {
        clearTimeout(clearMessageTimeoutRef.current);
      }

      clearMessageTimeoutRef.current = setTimeout(() => {
        setMessage({ ...DefaultMessage });
      }, 300); // this delay is slightly longer than it takes MessageTray to animate closing itself

      return () => {
        if (clearMessageTimeoutRef.current) {
          clearTimeout(clearMessageTimeoutRef.current);
        }
      };
    }
  }, [open]);

  return (
    <ThreadToastContext.Provider
      value={{
        toast,
        message,
        clear,
        open,
      }}
    >
      {children}
    </ThreadToastContext.Provider>
  );
}
