import { TextField, Theme } from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent, ReactNode, forwardRef, useEffect, useRef } from 'react';
import { FormProps } from '..';
import { createUseStyles } from 'react-jss';
import Icon, { IconType } from '../../icon';
import CopyClipButton from '@/components/copy-clip-button';

interface Props extends FormProps {
  value: string;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (value: string) => void;
  type?: string;
  autoComplete?: string;
  rows?: number;
  size?: 'small' | 'medium';
  placeholder?: string;
  clearable?: boolean;
  startIcon?: IconType;
  copyClip?: boolean;
  readonly?: boolean;
  maxLength?: number;
}

const useStyles = createUseStyles<string, { isTextarea: boolean }>((theme: Theme) => ({
  textfield: ({ isTextarea }) => ({
    '& label.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.common.black,
    },
    '& .MuiInputBase-root': {
      paddingRight: isTextarea ? 0 : 6,
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.grey[500],
        },
      },
      '&.Mui-readOnly fieldset': {
        borderColor: `${theme.palette.grey[200]} !important`,
      },
      '&.MuiInputBase-adornedStart': {
        paddingLeft: 6,
      },
      '& input.MuiInputBase-inputAdornedEnd': {
        paddingRight: 12,
      },
      '& input.Mui-readOnly, textarea.Mui-readOnly': {
        color: `${theme.palette.grey[600]} !important`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    ...(isTextarea && {
      '& textarea': {
        paddingRight: 14,
      },
    }),
  }),
  startAdornment: {
    marginTop: 4,
  },
  notchedOutline: {
    borderWidth: '1px !important',
  },
}));

const TextInput: FC<Props> = forwardRef(
  (
    {
      name,
      label = '',
      onChange,
      onEnter,
      type = 'text',
      autoComplete = '',
      autoFocus = false,
      value,
      error = '',
      disabled = false,
      required = false,
      rows,
      size,
      placeholder,
      clearable = false,
      copyClip = false,
      startIcon,
      readonly = false,
      maxLength,
    },
    ref
  ) => {
    const isSmall = size === 'small';
    const isTextarea = type === 'textarea';
    const styles = useStyles({ isTextarea });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (readonly) {
        return;
      }
      onChange && onChange(String(event.target.value), event);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && onEnter) {
        event.preventDefault();
        onEnter(value);
      }
    };

    const TextareaProps = {
      multiline: false,
      minRows: 5,
      maxRows: 5,
    };

    if (isTextarea) {
      TextareaProps.multiline = true;
      if (rows) {
        TextareaProps.minRows = rows;
        TextareaProps.maxRows = rows;
      }
    }

    const handleClear = () => {
      if (readonly) {
        return;
      }
      onChange && onChange('');
    };

    let EndAdornmentEl: ReactNode = null;
    const showClearIcon = clearable && Boolean(value);
    const hasStartIcon = Boolean(startIcon);

    if (showClearIcon) {
      EndAdornmentEl = <Icon className={styles.endAdornment} name="close" onClick={handleClear} />;
    }

    if (copyClip) {
      EndAdornmentEl = <CopyClipButton className={styles.endAdornment} value={value || ''} />;
    }

    const compRef = useRef<HTMLInputElement>(null);

    // make sure autofocus actually works
    // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
    useEffect(() => {
      if (!autoFocus) {
        return;
      }
      setTimeout(() => {
        (compRef || ref).current?.focus();
      });
    }, [compRef, ref, autoFocus]);

    return (
      <TextField
        id={name}
        name={name}
        label={label || undefined}
        variant="outlined"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value || ''}
        margin={isSmall ? 'none' : 'normal'}
        fullWidth
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        error={!!error}
        helperText={error}
        disabled={disabled}
        {...TextareaProps}
        className={styles.textfield}
        required={required}
        size={size}
        placeholder={placeholder}
        inputRef={ref || compRef}
        inputProps={{ maxLength }}
        InputProps={{
          classes: {
            notchedOutline: styles.notchedOutline,
          },
          startAdornment: hasStartIcon ? (
            <Icon className={styles.startAdornment} name="search" />
          ) : null,
          endAdornment: EndAdornmentEl,
          readOnly: readonly,
        }}
      />
    );
  }
);

export default TextInput;
