import { FC, ReactNode } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tooltip: {
    padding: 10,
  },
});

interface Props {
  children: ReactNode;
  title: ReactNode;
  className?: string;
  placement?:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined;
}

const Tooltip: FC<Props> = ({ children, title, placement = 'top', className = '' }) => {
  const styles = useStyles();
  return (
    <MuiTooltip
      title={title}
      placement={placement}
      slotProps={{ tooltip: { className: styles.tooltip } }}
    >
      <span className={className}>{children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
