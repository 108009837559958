import { ReactNode, RefObject, forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Portal, Slide, Theme } from '@mui/material';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

interface Props {
  height: number;
  containerRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  className?: string;
}

const TRAY_DURATION_MS = 250;

const useStyles = createUseStyles<string, { open: boolean; trayHeight: number }>(
  (theme: Theme) => ({
    trayContainer: {
      position: 'absolute',
      top: ({ trayHeight }) => trayHeight * -1,
      left: 0,
      width: '100%',
      zIndex: 1,
      height: ({ open, trayHeight }) => (open ? trayHeight : 0),
      transition: ({ open }) =>
        open ? '' : `height ${TRAY_DURATION_MS}ms ease-out ${TRAY_DURATION_MS + 20}ms`,
      overflow: 'hidden',
      '& .inner': {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
    },
    traySlider: {
      position: 'absolute',
      height: ({ trayHeight }) => trayHeight,
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
    },
    trayInner: {
      height: '100%',
      width: '100%',
    },
  })
);

export interface MessageTrayInstance {
  close: () => void;
  open: () => void;
  toggle: () => void;
}

const MessageTray = forwardRef<MessageTrayInstance, Props>(
  ({ containerRef, height, children, className }, ref) => {
    const [open, setOpen] = useState(false);

    const styles = useStyles({ open, trayHeight: height });

    useImperativeHandle(
      ref,
      () => {
        return {
          open: () => {
            setOpen(true);
          },
          close: () => {
            setOpen(false);
          },
          toggle: () => {
            setOpen(!open);
          },
        };
      },
      [open]
    );

    const compClass = classNames(styles.trayContainer, className);

    return (
      <Box ref={ref}>
        <Portal container={containerRef.current}>
          <Box className={compClass}>
            <Box className="inner">
              <Slide
                direction="up"
                in={open}
                mountOnEnter
                unmountOnExit
                timeout={TRAY_DURATION_MS}
                className={styles.traySlider}
              >
                <Box className={styles.trayInner}>{children}</Box>
              </Slide>
            </Box>
          </Box>
        </Portal>
      </Box>
    );
  }
);

export default MessageTray;
