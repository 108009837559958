import { JsonObject } from '../helpers';
import { NosqlView } from './nosql-view';

export class BaseView extends NosqlView {
  get _json(): JsonObject {
    const json: JsonObject = {};
    Object.getOwnPropertyNames(this).map((prop: string) => (json[prop] = this[prop as keyof this]));
    return JSON.parse(JSON.stringify(json)) as JsonObject;
  }

  get _props(): Record<string, unknown> {
    const props: Record<string, unknown> = {};
    Object.getOwnPropertyNames(this).map(
      (prop: string) => (props[prop] = this[prop as keyof this])
    );
    return props;
  }
}
