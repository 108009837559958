import { createContext, ReactNode, ReactElement, useReducer, useCallback, FC } from 'react';
import lowerFirst from 'lodash/lowerFirst';
import uniqueId from 'lodash/uniqueId';
import upperFirst from 'lodash/upperFirst';

import { FlatObject, JsonObject } from '../../lib/helpers';
import ConfirmModal from '../../components/modals/confirm';
import { isModalConfig } from './helpers';
import { addUrlQueryParam, getQueryStringParams, removeUrlQueryParam } from '@/lib/url-helpers';
import AppErrorModal from '@/components/modals/app-error';

export type ModalConfig = {
  key: string;
  Component: FC;
  modalType: ModalType;
  props?: JsonObject;
};

export type ModalElement = {
  key: string;
  element: ReactElement;
};

export type Modal = ModalConfig | ModalElement;

type ModalType = 'confirm' | 'user' | 'app-error';

const modalTypeMap: Map<ModalType, FC> = new Map();

modalTypeMap.set('confirm', ConfirmModal);
modalTypeMap.set('user', ConfirmModal);
modalTypeMap.set('app-error', AppErrorModal);

export interface IModalState {
  getModalLink: (linkId: string) => JsonObject | null;
  linkModal: (linkId: string, params?: JsonObject) => void;
  modalIsOpen: (modalType: ModalType) => boolean;
  openModal: <T>(modalType: ModalType, props?: T) => void;
  closeModal: () => void;
  modalStack: Modal[];
}

export const ModalContext = createContext<IModalState>({
  getModalLink: () => null,
  linkModal: () => null,
  modalIsOpen: () => false,
  openModal: () => null,
  closeModal: () => null,
  modalStack: [],
});

const updateModalStack = (state: Modal[], action: { type: 'open' | 'close'; modal?: Modal }) => {
  const updatedStack: Modal[] = [...state];
  const { type, modal } = action;

  switch (type) {
    case 'close':
      updatedStack.pop();
      break;
    case 'open':
      {
        if (!modal) {
          return updatedStack;
        }

        // modals based on configs must be unique by type, in the stack
        const openConfigs = updatedStack.filter(isModalConfig);
        if (
          isModalConfig(modal) &&
          openConfigs.find((stackModal) => stackModal.modalType === modal.modalType)
        ) {
          return updatedStack;
        }

        updatedStack.push(modal);
      }
      break;
  }

  return updatedStack;
};

export function ModalProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [modalStack, setModalStack] = useReducer(updateModalStack, []);

  const removeModalQsParams = useCallback(() => {
    const qsParams = getQueryStringParams();
    Object.keys(qsParams).forEach(
      (key: string) => key.match(/^modal.*/) && removeUrlQueryParam(key)
    );
  }, []);

  const closeModal = useCallback(
    (persistQsParams?: boolean) => {
      setModalStack({ type: 'close' });
      if (!persistQsParams) {
        removeModalQsParams();
      }
    },
    [removeModalQsParams]
  );

  const openModal = useCallback(<T,>(modalType: ModalType, props?: T) => {
    if (!modalTypeMap.has(modalType)) {
      console.warn('unknown modal type', modalType);
      return;
    }

    const Component = modalTypeMap.get(modalType)!;

    const newModalConfig = {
      Component,
      key: uniqueId('modal-'),
      props: { ...props },
      modalType,
    };

    setModalStack({ type: 'open', modal: newModalConfig });
  }, []);

  const modalIsOpen = useCallback(
    (modalType: ModalType): boolean => {
      return modalStack.some(
        (modal: Modal) => isModalConfig(modal) && modal.modalType === modalType
      );
    },
    [modalStack]
  );

  const linkModal = useCallback(
    (linkId: string, params?: FlatObject) => {
      removeModalQsParams();
      addUrlQueryParam('modal', linkId);

      // namespace extra parameters' keys with the 'modal' prefix so they can be identified/removed/retrieved in other contexts
      if (params) {
        Object.keys(params).forEach((key: string) =>
          addUrlQueryParam(`modal${upperFirst(key)}`, params[key])
        );
      }
    },
    [removeModalQsParams]
  );

  const getModalLink = useCallback((linkId: string): FlatObject | null => {
    const qs = getQueryStringParams();
    if (qs.modal !== linkId) {
      return null;
    }

    // remove the namespacing from extra parameters' keys that may have been added above in linkModal
    const plainQs: FlatObject = {};
    Object.keys(qs).forEach(
      (key: string) => (plainQs[lowerFirst(key.replace(/^modal(.+)/, '$1'))] = qs[key])
    );
    return plainQs;
  }, []);

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        getModalLink,
        linkModal,
        modalIsOpen,
        openModal,
        modalStack,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
