import usePrior from '@/hooks/use-prior.hook';
import useThread from '@/hooks/use-thread.hook';
import { Box, Stack, TextField, Theme } from '@mui/material';
import {
  useEffect,
  useState,
  useRef,
  KeyboardEvent,
  useCallback,
  ChangeEvent,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { createUseStyles } from 'react-jss';
import { debounce } from 'lodash';
import UploadForm, { UploadFormInstance } from './upload-form';
import FlatButton from '@/components/flat-button';
import ThreadToastTray from './thread-toast-tray';
import { makeDeckFileMessage } from '@/lib/services/thread.service';
import { ApiFileResponse } from '@/lib/services/index';
import { improvePrompt, isGoodImproveCandidate } from '@/lib/services/prompt.service';
import { LoadState, scrollToCursor } from '@/lib/helpers';
import Shimmer from '@/components/shimmer';
import useThreadToast from '@/hooks/use-thread-toast.hook';
import Chip from '@/components/chip';
import Tooltip from '@/components/tooltip';

const useStyles = createUseStyles<string, { improveLoading: boolean }>((theme: Theme) => ({
  messageControl: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  messageInput: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& textarea': {
        padding: 12,
        opacity: ({ improveLoading }) => (improveLoading ? 0.5 : 1),
      },
      '& fieldset': {
        border: 'none !important',
      },
    },
  },
  toolbar: {
    padding: '5px 10px',
  },
  improveButton: {
    cursor: 'pointer',
  },
}));

export interface MessageControlInstance {
  loadContent: (content: string) => void;
  loadFiles: (files: FileList) => void;
  focus: () => void;
}

const MessageControl = forwardRef<MessageControlInstance>((_props, ref) => {
  const [message, setMessage] = useState('');
  const [canImprove, setCanImprove] = useState(false);
  const [improveLoadState, setImproveLoadState] = useState<LoadState>('unloaded');

  const inputRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<UploadFormInstance>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { toast: threadToast } = useThreadToast();

  const { thread, sendMessage, messageStatus, addMessage, closeStream } = useThread();
  const messageOpen = messageStatus === 'open';
  const styles = useStyles({ improveLoading: improveLoadState === 'loading' });

  const hasOnDeckShortcut = useMemo(() => {
    return thread.messages.some(({ onDeck, shortcut }) => onDeck && !!shortcut);
  }, [thread]);

  const canSend = useMemo(
    () => !!message && messageOpen && !hasOnDeckShortcut && improveLoadState !== 'loading',
    [message, messageOpen, hasOnDeckShortcut, improveLoadState]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetCanImprove = useCallback(
    debounce((message: string) => {
      setCanImprove(isGoodImproveCandidate(message));
    }, 200),
    []
  );

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      loadContent: (content: string) => {
        setMessage(content);
        inputRef.current?.focus();
      },
      loadFiles: (files: FileList) => {
        uploadRef.current?.loadFiles(files);
      },
    };
  }, []);

  // add a newline when pressing enter+shift|ctrl|meta
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter' || !inputRef.current) {
      return;
    }

    event.preventDefault();

    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      const textarea = inputRef.current;
      const start = textarea.selectionStart || 0;
      const end = textarea.selectionEnd || 0;

      setMessage((old) => `${old.substring(0, start)}\n${old.substring(end)}`);

      // place the cursor after the newline
      setTimeout(() => {
        textarea.selectionStart = start + 1;
        textarea.selectionEnd = start + 1;
        scrollToCursor(textarea);
      }, 10);
      return;
    }

    handleSendMessage();
  };

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = useCallback(() => {
    if (!canSend) {
      return;
    }

    uploadRef.current?.close();

    sendMessage(message);

    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(0, 0);
    setMessage('');
  }, [message, sendMessage, canSend]);

  const handleImprovePrompt = useCallback(() => {
    if (!canImprove) {
      return;
    }

    inputRef.current?.blur();
    setCanImprove(false);
    setImproveLoadState('loading');

    improvePrompt(message, thread.id).then(({ prompt, error }) => {
      setImproveLoadState('loaded');

      if (error) {
        threadToast(error, 'error');
        return;
      }

      if (prompt) {
        setMessage(prompt);
      }

      setTimeout(() => {
        scrollToCursor(inputRef.current!);
      }, 10);
    });
  }, [message, canImprove, threadToast, thread.id]);

  const handleUpload = useCallback(
    (file: ApiFileResponse) => {
      const { name, summary } = file;
      if (!name) {
        return;
      }

      const uploadMessage = makeDeckFileMessage(thread.id, name, summary || '');
      addMessage(uploadMessage);
    },
    [addMessage, thread.id]
  );

  const handleCancelMessage = () => {
    closeStream();
  };

  const priorThread = usePrior(thread);
  useEffect(() => {
    if (thread.id && priorThread?.id && thread.id !== priorThread.id) {
      inputRef.current?.focus();
    }
  }, [thread, priorThread]);

  useEffect(() => {
    debounceSetCanImprove(message);
  }, [debounceSetCanImprove, message]);

  const locked = !!thread.locked;
  const improveLoading = improveLoadState === 'loading';

  return (
    <Box className={styles.messageControl} ref={containerRef}>
      <Shimmer disabled={!improveLoading}>
        <TextField
          id="message"
          name="message"
          className={styles.messageInput}
          variant="outlined"
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
          value={message}
          margin="none"
          fullWidth
          type="textarea"
          autoFocus
          multiline
          minRows={2}
          maxRows={8}
          placeholder="Enter your message"
          inputRef={inputRef}
          disabled={locked}
          inputProps={{
            readOnly: improveLoading,
          }}
        />
      </Shimmer>
      <Stack direction="row" justifyContent="flex-end" gap={1.5} className={styles.toolbar}>
        {!messageOpen && (
          <FlatButton icon="cancel" label="Cancel" onClick={handleCancelMessage} size="small" />
        )}

        <Tooltip title="Clicking Enhance BETA will use GenAI to improve the quality of your prompt. This feature is available for prompts that are a target length.">
          <Stack
            direction="row"
            gap={0.5}
            alignItems="center"
            mr={1}
            role="button"
            onClick={handleImprovePrompt}
            className={styles.improveButton}
          >
            <FlatButton icon="magic" label="Enhance" size="small" disabled={!canImprove} />
            <Chip label="BETA" size="x-small" color="warning" />
          </Stack>
        </Tooltip>
        <UploadForm
          key={`thread-${thread.id}`}
          threadId={thread.id}
          ref={uploadRef}
          containerRef={containerRef}
          onUpload={handleUpload}
          disabled={locked}
        />
        <FlatButton
          icon="arrow-circle-up"
          label="Send"
          onClick={handleSendMessage}
          size="small"
          disabled={!canSend}
        />
      </Stack>
      <ThreadToastTray containerRef={containerRef} />
    </Box>
  );
});

export default MessageControl;
