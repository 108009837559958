import { LoadState } from '@/lib/helpers';
import { ShortcutMessage, ShortcutModel } from '@/lib/models/shortcut.model';
import { Thread, ThreadMessage } from '@/lib/services/thread.service';
import { createContext } from 'react';

export type MessageStatus = 'open' | 'send' | 'stream';

export interface ThreadSettings {
  loadState: LoadState;
  thread: Thread;
  sendMessage: (content: string, shortcut?: ShortcutMessage) => boolean;
  messageStatus: MessageStatus;
  working: boolean;
  streamContent: string;
  setWorking: (working: boolean) => void;
  loadThread: (threadId?: string) => Promise<boolean>;
  loadShortcut: (shortcut: ShortcutModel) => boolean;
  addMessage: (MediaCapabilities: ThreadMessage) => void;
  sendDeckMessage: (message: ThreadMessage) => boolean;
  removeDeckMessage: (messageId: string) => void;
  closeStream: () => void;
}

export const ThreadContext = createContext<ThreadSettings>({
  loadState: 'unloaded',
  thread: { id: '', messages: [], context: new Map() },
  sendMessage: () => false,
  messageStatus: 'open',
  working: false,
  streamContent: '',
  setWorking: () => null,
  loadThread: () => Promise.resolve(false),
  loadShortcut: () => false,
  addMessage: () => null,
  sendDeckMessage: () => false,
  removeDeckMessage: () => null,
  closeStream: () => null,
});

export enum ThreadError {
  NotFoundThread = 'The thread was not found',
  NotFoundMessage = 'The message was not found',

  // loadThread
  SendingSwitchThread = 'Please wait for a response before opening a different thread',
  WorkingSwitchThread = 'Please complete or cancel the current shortcut before opening a different thread',

  // loadShortcut
  SendingLoadShortcut = 'Please wait for a response before opening a shortcut',
  WorkingLoadShortcut = 'Please complete or cancel the current shortcut before opening another one',

  // sendMessage
  // sendDeckMessage
  Sending = 'Please wait for a response before sending a message',
  Working = 'Please complete or cancel the current shortcut before sending a message',

  NoDisassemble = 'There was a network error',
}
