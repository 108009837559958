import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  children: ReactNode;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  shimmer: {
    '-webkit-mask': 'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
    backgroundRepeat: 'no-repeat',
    animation: '$shimmer 1.5s infinite',
  },

  '@keyframes shimmer': {
    '100%': {
      WebkitMaskPosition: 'left',
    },
  },
});

const Shimmer: FC<Props> = ({ children, disabled = false }) => {
  const styles = useStyles();

  return <Box className={disabled ? '' : styles.shimmer}>{children}</Box>;
};

export default Shimmer;
