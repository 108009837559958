import { Select as MuiSelect, MenuItem, Theme, ListSubheader } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import FormControl from '../form-control';
import { FormProps } from '..';
import { UiOption } from '@/lib/helpers';
import { createUseStyles } from 'react-jss';
import Text from '@/components/text';

interface Props extends FormProps {
  value: string;
  options: UiOption[];
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'medium' | 'small';
}

const useStyles = createUseStyles((theme: Theme) => ({
  select: {
    '& fieldset': {
      borderWidth: '1px !important',
    },
    '&.Mui-disabled fieldset': {
      borderColor: `${theme.palette.grey[600]} !important`,
    },
  },
  subheader: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    lineHeight: '30px',
    marginTop: 10,
    paddingLeft: 8,
  },
}));

const Select: FC<Props> = ({
  name,
  value = '',
  options,
  label = '',
  disabled = false,
  autoFocus = false,
  onChange,
  error = '',
  size = 'medium',
  fullWidth = false,
  readonly = false,
  required = false,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(String(event.target.value), event);

  const labelId = `${name}-label`;
  const styles = useStyles();

  return (
    <FormControl label={label} error={error} name={name} size={size}>
      <MuiSelect
        labelId={labelId}
        id={name}
        name={name}
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled || readonly}
        autoFocus={autoFocus}
        error={!!error}
        size={size}
        fullWidth={fullWidth}
        className={styles.select}
        required={required}
      >
        {options.map(({ value, label, disabled = false, meta = {} }) => {
          if (meta.header) {
            return (
              <ListSubheader className={styles.subheader} key={value}>
                {label}
              </ListSubheader>
            );
          }

          return (
            <MenuItem key={value} value={value} disabled={disabled}>
              <Text color="black">{label}</Text>
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
