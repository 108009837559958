import Cookies from 'js-cookie';
import { JsonObject } from './helpers';

const cookieApi = Cookies.withAttributes({ path: '/', secure: true, sameSite: 'strict' });

export const getCookie = (name: string): string | null => cookieApi.get(name) || null;

export const getCookieJson = (name: string): JsonObject | null => {
  const val = getCookie(name);
  if (!val) {
    return null;
  }

  try {
    const data = JSON.parse(val) as JsonObject;
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const setCookie = (name: string, val: string, options: JsonObject = {}): boolean => {
  cookieApi.set(name, val, options);
  return true;
};

export const setCookieJson = (name: string, val: JsonObject, options: JsonObject = {}): boolean => {
  try {
    const data = JSON.stringify(val);
    setCookie(name, data, options);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deleteCookie = (name: string): boolean => {
  cookieApi.remove(name);
  return true;
};
