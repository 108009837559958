import { firebaseAuth } from './firebase.service';
import { User, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { isProd } from '../environment';
import axios, { AxiosError } from 'axios';
import { JsonObject, getFirstLastName } from '../helpers';
import { ApiResponse, handleResponse } from './index';
import { getUnixTime } from 'date-fns';
import { getUrlQueryParam, removeUrlQueryParam } from '../url-helpers';

export type AuthRole = 'org:owner' | 'org:admin' | 'org:auditor' | 'org:user';

export type AuthPermission = 'manage-admins' | 'read' | 'write';

export type AuthUser = User & {
  token: string;
  orgId: string;
  role: AuthRole;
  spUserId: string;
  groups: string[];
};

class AuthProviderModel {
  user: AuthUser | null = null;

  get token(): string {
    return this.user?.token || '';
  }

  get orgId(): string {
    return this.user?.orgId || '';
  }

  get spUserId(): string {
    return this.user?.spUserId || '';
  }

  get groups(): string[] {
    return this.user?.groups || [];
  }
}

export const AuthProvider = new AuthProviderModel();

const axiosClient = axios.create({
  baseURL: '',
  timeout: 10000,
  withCredentials: false,
  responseType: 'json',
  headers: {
    'X-Requested-By': 'surepath',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }

  return config;
});

const get = async (path: string, params?: JsonObject): Promise<ApiResponse> => {
  return axiosClient.get(path, { params }).then(handleResponse);
};

export const userCan = (user: AuthUser | null | undefined, perm: AuthPermission): boolean => {
  const role = user?.role;
  if (!role) {
    return false;
  }

  if (role === 'org:owner') {
    return true;
  }

  switch (perm) {
    case 'read':
      return ['org:admin', 'org:auditor', 'org:user'].includes(role);
    case 'manage-admins':
      return false;
    case 'write':
      return ['org:admin'].includes(role);
  }
};

const getAuthBaseUrl = (): string => {
  let domain = 'auth.surepath.ai';

  if (!isProd()) {
    const [, environment] = window.location.hostname.split('.');

    if (['dev', 'stage', 'local'].includes(environment)) {
      domain = `auth.${environment}.surepath.ai`;
    }
  }

  return `https://${domain}`;
};

export const getAuthRedirectUrl = (fromAuthSite?: boolean): string => {
  // user was just redirected here from auth? don't redirect them back
  const fas = typeof fromAuthSite === 'boolean' ? fromAuthSite : getFromAuthSite();
  if (fas) {
    return '/error?code=500';
  }

  const redirectDest = encodeURIComponent(window.location.origin);
  return `${getAuthBaseUrl()}/login?redirect_to=${redirectDest}`;
};

export const getAuthNoAccessUrl = (): string => {
  return `${getAuthBaseUrl()}/splash?action=portal-access`;
};

export const getFromAuthSite = (): boolean => {
  const fromAuthSite = getUrlQueryParam('fas');
  removeUrlQueryParam('fas');
  return Boolean(fromAuthSite);
};

export const signOut = async (): Promise<boolean> => {
  try {
    await firebaseAuth.signOut();
    window.location.href = `${getAuthBaseUrl()}/logout?redirect_to=${window.location.origin}`;
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getCurrentUser = async (): Promise<AuthUser | null> => {
  const fbAuthUser = await getCurrentFirebaseUser(true);

  if (fbAuthUser) {
    console.log('auth from local');
    AuthProvider.user = fbAuthUser;
    return fbAuthUser;
  }

  const spTokenUser = await getCurrentTokenUser();

  if (spTokenUser) {
    console.log('auth from sp-token');
    AuthProvider.user = spTokenUser;
    return spTokenUser;
  }

  return null;
};

/*
 * Ensure that the JWT token is refreshed periodically so that other, non-firebase services can always
 * have an up-to-date token. Note that while Firebase will lazy-refresh the token for itself, other
 * services (like AtlasDataAPI) need a synchronous way of referencing an up-to-date token.
 *
 * While it seems as though you can listen for auth token expiration using onIdTokenChanged, this handler does not
 * actually fire when or before that happens:
 * https://github.com/firebase/firebase-js-sdk/issues/2985
 * https://github.com/firebase/firebase-js-sdk/blob/e9ff107eedbb9ec695ddc35e45bdd62734735674/packages/auth/src/core/index.ts#L136
 *
 */
export const observeTokenChange = () => {
  firebaseAuth.onIdTokenChanged((user: User) => {
    console.log('local auth change', user?.uid);
    if (!user) {
      AuthProvider.user = null;
      return;
    }

    getAuthUser(user).then((authUser) => {
      AuthProvider.user = authUser;
    });
  });

  const intMinutes = 1000 * 60 * 8;

  setInterval(() => {
    firebaseAuth.currentUser?.getIdTokenResult().then((result) => {
      const expTime = result?.claims?.exp;
      if (!expTime) {
        return;
      }

      const minuteDiff = (Number(expTime) - getUnixTime(new Date())) / 60;

      if (minuteDiff < 20) {
        firebaseAuth.currentUser?.getIdToken(true);
      }
    });
  }, intMinutes);
};

const getCurrentTokenUser = async (): Promise<AuthUser | null> => {
  let spAccessToken = '';

  try {
    const response = await get('/auth/status');

    const { accessToken, tenantId } = (response as { accessToken: string; tenantId: string }) || {};

    if (!accessToken || !tenantId) {
      return null;
    }

    firebaseAuth.tenantId = tenantId;

    spAccessToken = accessToken;
  } catch (err) {
    const axiosError = err as AxiosError;
    // cookie is probably just missing
    if (axiosError.response?.status === 400) {
      return null;
    }
    console.error(err);
    return null;
  }

  const result = await signInWithCustomToken(firebaseAuth, spAccessToken);

  if (!result?.user) {
    return null;
  }

  return getAuthUser(result.user);
};

const getCurrentFirebaseUser = async (forceRefresh?: boolean): Promise<AuthUser | null> => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user) {
        resolve(null);
        return;
      }

      unsubscribe();

      getAuthUser(user, forceRefresh).then(resolve);
    });
  });
};

const isValidCalimValue = (claimVal: string | null | undefined): boolean =>
  !!claimVal && claimVal !== 'none';

/*
 * Given a Firebase User, get their parsed JWT and then validate that important
 * authentication parameters are present. Return an AuthUser to act as the primary
 * interface for the auth service and the rest of the application, as opposed to directly
 * handling the Firebase User object.
 */
const getAuthUser = async (user: User, forceRefresh = false): Promise<AuthUser | null> => {
  const userWithToken = user as User & { accessToken: string };

  // user missing critical info
  if (!userWithToken.tenantId || !userWithToken.accessToken) {
    console.error('user missing tenant id or access token');
    return null;
  }

  const result = await user.getIdTokenResult(forceRefresh);
  const { claims, token } = result || {};

  if (!claims || !token) {
    console.error('user missing claims or token');
    return null;
  }

  const { org_id, role, sp_user_id, groups } = claims as {
    org_id: string;
    role: string;
    sp_user_id: string;
    groups: string[];
  };

  /*
   * Rather than returning null here, simply warn and then return an AuthUser
   * object which does not have read permissions. This way, the user can be
   * redirected to the portal-no-access page, rather than being handled as if
   * they are not logged in.
   */
  if (!isValidCalimValue(org_id) || !isValidCalimValue(role)) {
    console.warn('user missing org_id/role', claims);
  }

  const authUser: AuthUser = {
    ...user,
    orgId: org_id || '',
    role: (role as AuthRole) || null,
    token,
    groups,
    spUserId: sp_user_id,
  };

  return authUser;
};

export const getUserDetails = (
  user: AuthUser
): {
  hasPhoto: boolean;
  hasName: boolean;
  first: string;
  last: string;
  username: string;
  photoURL: string;
  email: string;
} => {
  const { displayName, photoURL, email } = user;
  const username = displayName || '';
  const { first, last } = getFirstLastName(username);
  const hasPhoto = Boolean(photoURL);
  const hasName = Boolean(first) || Boolean(last);

  return {
    hasPhoto,
    hasName,
    first,
    last,
    username,
    photoURL: photoURL || '',
    email: email || '',
  };
};
