import { FC, RefObject, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { createUseStyles } from 'react-jss';
import MessageTray, { MessageTrayInstance } from './message-tray';
import InlineToast from '@/components/inline-toast';
import useThreadToast from '@/hooks/use-thread-toast.hook';

interface Props {
  containerRef: RefObject<HTMLDivElement>;
}

const useStyles = createUseStyles({
  toastPortal: {
    zIndex: 2,
  },
  toastContainer: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: 20,
  },
});

export interface UploadFormInstance {
  close: () => void;
}

const ThreadToastTray: FC<Props> = ({ containerRef }) => {
  const trayRef = useRef<MessageTrayInstance>(null);
  const styles = useStyles();
  const { message, open } = useThreadToast();

  useEffect(() => {
    if (open) {
      trayRef.current?.open();
      return;
    }

    trayRef.current?.close();
  }, [open]);

  const { content, variant } = message || {};
  const hasMessage = !!content && !!variant;
  const alertColor = variant === 'default' ? 'info' : variant;

  return (
    <MessageTray
      containerRef={containerRef}
      height={75}
      ref={trayRef}
      className={styles.toastPortal}
    >
      <Box className={styles.toastContainer}>
        {hasMessage && <InlineToast message={content} level={alertColor} />}
      </Box>
    </MessageTray>
  );
};

export default ThreadToastTray;
