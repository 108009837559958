import { FC, ReactNode } from 'react';
import Page from '../../components/page';
import { createUseStyles } from 'react-jss';
import Card from '@/components/card';
import { Stack } from '@mui/material';

interface Props {
  children: ReactNode;
  height?: number | string;
}

const useStyles = createUseStyles({
  container: {
    margin: '8px auto',
    width: 500,
  },
});

const PageCard: FC<Props> = ({ children, height = 400 }) => {
  const styles = useStyles();

  return (
    <Page>
      <Card className={styles.container}>
        <Stack alignItems="center" justifyContent="center" gap={2} minHeight={height}>
          {children}
        </Stack>
      </Card>
    </Page>
  );
};

export default PageCard;
