import { FlatObject } from './helpers';

// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export const getQueryStringParams = (qs?: string): Record<string, string> => {
  if (!qs) {
    qs = window.location.search.substring(1);
  }

  let match;
  const pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = (s: string) => decodeURIComponent(s.replace(pl, ' '));
  const urlParams: FlatObject = {};
  while ((match = search.exec(qs))) {
    urlParams[decode(String(match[1]))] = decode(String(match[2]));
  }

  return urlParams;
};

export const makeQueryString = (params: FlatObject): string => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const addUrlQueryParam = (key: string, value: string) => {
  const qs = getQueryStringParams();
  qs[key] = value;

  const url = window.location.href.split('?').shift();
  window.history.pushState(null, '', `${url}?${makeQueryString(qs)}`);
};

export const removeUrlQueryParam = (key: string) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  url.searchParams.delete(key);
  url.search = searchParams.toString();
  window.history.replaceState({}, document.title, url.toString());
};

export const getUrlQueryParam = (key: string): string | null => {
  const url = new URL(window.location.href);
  const val = url.searchParams.get(key);
  return val ? decodeURIComponent(val) : null;
};

// https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window
export const openNewTab = (url: string, target = ''): void => {
  window.open(url, target)?.focus();
};

export const getHost = (): string => String(window.location.origin).replace(/^http(s?):\/\//, '');

export const pathIsActive = (path: string): boolean => {
  const currentPath = window.location.pathname;
  return currentPath === path;
};

export const pathIsExternal = (path: string): boolean =>
  Boolean(String(path).match(/^http(s?):\/\//));

export const withoutProto = (s: string): string => s.replace(/^http(s?):\/\//, '');

export const getDomain = (source: string): string => {
  try {
    const sourceUrl = new URL(source);
    return sourceUrl?.hostname || '';
  } catch {
    return '';
  }
};
