import { Box, Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import Text from '../text';
import { createUseStyles } from 'react-jss';
import TextInput from '../form/text-input';
import useThread from '@/hooks/use-thread.hook';
import { useQuery } from 'react-query';
import { getShortcuts } from '@/lib/services/shortcut.service';
import SidebarList from './sidebar-list';
import Icon from '../icon';
import { QueryKey } from '@/lib/query-client';

const useStyles = createUseStyles({
  header: {
    padding: '0px 12px 10px 18px',
  },
  searchInput: {
    marginTop: 20,
    paddingRight: 6,
  },
});

const ShortcutTab: FC = () => {
  const styles = useStyles();
  const [keyword, setKeyword] = useState('');

  const { loadShortcut } = useThread();

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const { data = [], isLoading } = useQuery([QueryKey.ShortcutsList], async () => getShortcuts());

  const listItems = useMemo(() => {
    const safeKeyword = String(keyword).trim().toLowerCase();

    const filteredData = data.filter(
      ({ name, description }) =>
        String(name).toLowerCase().includes(safeKeyword) ||
        String(description).toLowerCase().includes(safeKeyword)
    );

    return filteredData.map((shortcut) => {
      const { id, name, description } = shortcut;

      return {
        key: id!,
        title: name,
        content: description,
        onClick: () => loadShortcut(shortcut),
      };
    });
  }, [data, keyword, loadShortcut]);

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <Stack gap={2} className={styles.header}>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon name="shortcut" />
          <Text size="large">Shortcuts</Text>
        </Stack>

        <Box className={styles.searchInput}>
          <TextInput
            name="shortcut-search"
            value={keyword}
            onChange={handleKeywordChange}
            size="small"
            startIcon="search"
            placeholder="Search shortcuts"
            clearable
          />
        </Box>
      </Stack>

      <SidebarList isLoading={isLoading} items={listItems} />
    </Stack>
  );
};

export default ShortcutTab;
