import { useEffect } from 'react';

const useFavicon = (faviconUrl: string) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const linkElement = (document.querySelector("link[rel*='icon']") ||
      document.createElement('link')) as HTMLLinkElement;
    linkElement.type = 'image/x-icon';
    linkElement.rel = 'shortcut icon';
    linkElement.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(linkElement);
  }, [faviconUrl]);
};

export default useFavicon;
