import { AxiosError, AxiosProgressEvent, CanceledError } from 'axios';
import { FileWithPath } from 'react-dropzone';
import { ApiFileResponse, ApiUploadResponse } from './index';
import { JsonObject } from '../helpers';
import { get, post } from './sp-api.service';

export type AllowedFiletype = { extension: string; mimetype: string };

export type FileSettings = {
  maxFileSizeBytes: number;
  maxFilesPerUpload: number;
  allowedFileTypes: AllowedFiletype[];
};

export const uploadContextFile = async (
  file: FileWithPath,
  threadId: string,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  controller?: AbortController
): Promise<ApiUploadResponse> => {
  return postUploadFile(`/files/context/${threadId}`, file, onUploadProgress, controller).catch(
    (err) => {
      // user cancelled the upload
      if (err instanceof CanceledError) {
        return { threadId, error: '' };
      }

      const { status } = (err as AxiosError)?.response || {};
      let error = 'There was a network error';

      // cloudflare probably rejected the file
      if (status === 403) {
        error = 'The file was blocked due to security concerns';
      } else if (String(status).startsWith('5')) {
        error = 'There was a service error';
      }

      const apiFile: ApiFileResponse = {
        name: file.name,
        summary: '',
        purpose: '',
      };

      return { file: apiFile, threadId, error };
    }
  );
};

export const getSettings = async (): Promise<FileSettings> => {
  const response = await get('/files/settings');

  if (!response) {
    return {
      maxFileSizeBytes: 0,
      maxFilesPerUpload: 0,
      allowedFileTypes: [],
    };
  }

  return response as FileSettings;
};

const postUploadFile = async (
  path: string,
  file: FileWithPath,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
  controller?: AbortController
): Promise<ApiUploadResponse> => {
  const formData = new FormData();
  formData.append('files', file, file.name);

  return post(path, formData, {
    withCredentials: false,
    signal: controller?.signal || new AbortController().signal,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  }).then((data: JsonObject) => {
    const { files, threadId, error } = data as {
      files: ApiFileResponse[];
      threadId: string;
      error?: string;
    };

    const file: ApiFileResponse | undefined = files?.[0] || undefined;

    return {
      file,
      threadId,
      error,
    };
  });
};
