import { object } from 'yup';
import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

export type PortalWelcome = {
  title: string;
  message: string;
  learnMoreLink: string;
};

export type PortalBadge = {
  image: string;
  title: string;
};

export class OrgBrandingModel extends BaseModel {
  orgName: string;
  lightThemeLogo: string;
  portalBadge: {
    image: string;
    title: string;
  };
  portalWelcome: PortalWelcome;
  supportEmail: string;
  favicon: string;

  schema = object({});

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof OrgBrandingModel, unknown>;

    this.orgName = (data.orgName as string) || '';
    this.lightThemeLogo = (data.lightThemeLogo as string) || '';
    this.portalBadge = (data.portalBadge as PortalBadge) || {
      image: '',
      title: '',
    };

    this.portalWelcome = (data.portalWelcome as PortalWelcome) || {
      title: '',
      message: '',
      learnMoreLink: '',
    };

    this.supportEmail = (data.supportEmail as string) || '';

    this.favicon = (data.favicon as string) || '';
  }

  get badgeTitle(): string {
    return this.portalBadge.title || 'Assistant';
  }

  get enabled(): boolean {
    return !!this.lightThemeLogo;
  }
}
