import OrgBadge from '@/components/org-badge';
import Text from '@/components/text';
import useThread from '@/hooks/use-thread.hook';
import { Box, Fade, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.common.white,
  },
}));

const ThreadOverlay: FC = () => {
  const styles = useStyles();
  const { thread, loadState } = useThread();
  const hasMessages = !!thread.messages.length;
  const threadLoading = loadState === 'loading';

  return (
    <>
      <Fade in={threadLoading} className={styles.overlay} mountOnEnter unmountOnExit timeout={90}>
        <Box></Box>
      </Fade>
      {!hasMessages && (
        <Stack className={styles.overlay} gap={3} alignItems="center" justifyContent="center">
          <OrgBadge />
          <Text>What would you like to get done today?</Text>
        </Stack>
      )}
    </>
  );
};

export default ThreadOverlay;
