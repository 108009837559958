import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import useApp from '@/hooks/use-app.hook';

const useStyles = createUseStyles({
  orgLogo: {
    display: 'block',
    maxWidth: 250,
    maxHeight: 40,
    width: 'auto',
    height: 'auto',
  },
});

const OrgLogo: FC = () => {
  const styles = useStyles();
  const { branding } = useApp();

  const { orgName = '', lightThemeLogo } = branding;
  const hasLogo = !!lightThemeLogo;

  if (!hasLogo) {
    return null;
  }

  return <img src={lightThemeLogo} alt={orgName} className={styles.orgLogo} />;
};

export default OrgLogo;
