import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC } from 'react';
import Button from '../../button';
import Modal from '..';
import useApp from '@/hooks/use-app.hook';

interface Props {
  error: string;
}

export const AppErrorModal: FC<Props> = ({ error }) => {
  const { signOut } = useApp();
  const handleReload = () => {
    window.location.reload();
  };
  const handleSignOut = () => {
    signOut();
  };

  return (
    <Modal preventClose>
      <DialogTitle>Application Error</DialogTitle>
      <DialogContent>{error}</DialogContent>
      <DialogActions>
        <Button onClick={handleSignOut} type="cancel" label="Sign Out" />
        <Button onClick={handleReload} label="Reload Page" />
      </DialogActions>
    </Modal>
  );
};

export default AppErrorModal;
